import React, { useEffect, useState, useRef, useContext } from "react";
import { ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  ThemeProvider,
  TextField,
  FormLabel,
  Stack,
  LinearProgress,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Alert,
  Modal,
  Drawer,
  Chip,
  Avatar,
} from "@mui/material";
// import { useMediaQuery } from "@mui/material";
import { useMediaQuery } from "@mui/material";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, createFilterOptions, Autocomplete } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
// import {
//   KeyboardArrowRight,
//   RocketLaunch,
//   Whatshot,
// } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import toast, { Toaster, ToastBar } from "react-hot-toast";
// import PopularityChart from "./PopularityChartV2";
// import IndustrialUsageChart from "./IndustrialUsageChartV2";
import PotentialSalaryIncraeseChart from "./PotentialSalaryIncraeseChart";
// import LoadingButton from "@mui/lab/LoadingButton";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import DeviceInfoAndLocation from "./global/DeviceInfoAndLocation";
// import SpeedDial from "@mui/material/SpeedDial";
// import ShareIcon from "@mui/icons-material/Share";
// import SpeedDialAction from "@mui/material/SpeedDialAction";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import { RWebShare } from "react-web-share";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
// import InfoIcon from "@mui/icons-material/Info";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import HelpIcon from "@mui/icons-material/Help";
import Joyride from "react-joyride";
import { OAuthCtx } from "./auth/AuthContext";
import { Auth } from "aws-amplify";
import { setupAuthInterceptor } from "./utils/apis";
import SignInModal from "./auth/components/SignIn";
import { getEmail, getUsername, setUserEmail } from "./utils/storage";
import LogoutIcon from "@mui/icons-material/Logout";
// import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import apis from "./utils/apis";
import { checkYearWithGpt, Legend } from "./utils/GlobalFunctions";
import { getExperiences } from "./Components/GlobalFunctions";
import { motion } from "framer-motion";

import GetQuestionForm from "./Components/common/GetQuestionForm";
import JobSuggestionDataCard from "./Components/common/JobSuggestionDataCard";
import FooterComponent from "./Components/Footer";
import { getQuestionForm } from "./Components/TechRelevance.helper";
import { setQuestionForm } from "./redux/action/questionForm";
import { useDispatch, useSelector } from "react-redux";
// import CancelIcon from "@mui/icons-material/Cancel";
// import SubSkills from "./Components/Subskills";
import Loading from "./Components/common/Loading";
import ErrorComponent from "./Components/common/Error";
// import Lottie from "react-lottie";
import ai from "./Components/animations/ai.json";
// import loadingAnimation from "./Components/animations/loading.json";
import EmojiIcon from "./Components/common/EmojiIcon";
import InsightsSkeleton from "./Components/common/InsightsSkeleton";
import GetInsights from "./Components/GetInsights";
import { fetchInterviewQuestions } from "./Components/techrelevance.apis/fetchInterviewQuestions";

import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

// import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

// const emojis = ["😀", "🎉", "🌟", "🚀", "💻", "🦄", "🔥"];

function getFirstWord(inputString) {
  const words = inputString.split(" ");
  if (words.length > 0) {
    return words[0];
  } else {
    return ""; // Return an empty string if there are no words in the input
  }
}

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 10,
    },
  },
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 10,
      delay: 0.2, // Delay the text's appearance
    },
  },
};

function ScrollDown() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: ai,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function TechRelevanceV4() {
  const theme = useTheme();
  const { setAuthState } = useContext(OAuthCtx);
  const { getAuthState } = useContext(OAuthCtx);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const relevenceTheme = createTheme({
    typography: {
      // fontFamily: [" Montserrat", "sans-serif"].join(","),
      fontFamily: `"Montserrat", 'normal'`,
    },
    palette: {
      primary: {
        main: "rgba(108,82,229,1)",
      },
      // secondary: {
      //   main: green[500],
      // },
    },
  });
  relevenceTheme.typography.h1 = {
    fontSize: "3rem",
    lineHeight: "3rem",
    "@media (max-width:600px)": {
      fontSize: "2.2rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  };
  relevenceTheme.typography.h2 = {
    fontSize: "2.125rem",
    lineHeight: "2.125rem",

    "@media (max-width:600px)": {
      fontSize: "1.625rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };
  relevenceTheme.typography.h3 = {
    fontSize: "1.75rem",
    lineHeight: "1.75rem",

    "@media (max-width:600px)": {
      fontSize: "1.375rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "1.6rem",
    },
  };
  relevenceTheme.typography.h4 = {
    fontSize: "1.5rem",
    lineHeight: "2rem",

    "@media (max-width:600px)": {
      fontSize: "1.25rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  relevenceTheme.typography.h5 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  relevenceTheme.typography.h6 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  relevenceTheme.typography.h6 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [relevenceTheme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  const [openTooltip, setOpenToolTip] = React.useState({});
  const methods = useForm({ criteriaMode: "all" });
  const methodsJobSuggestion = useForm({ criteriaMode: "all" });
  const [UserName, setUserName] = useState("");
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [relevanceResult, setRelevanceResult] = useState("");
  const [formdata, setFormdata] = useState("");
  const [chatGptButton, setChatGptButton] = useState(false);
  const [interviewQuestionsLoading, setInterviewQuestionsLoading] =
    useState(false);
  const [checkSkill, setCheckSkill] = useState("");
  const [skillList, setSkillList] = useState();
  const [interviewQuestions, setInterviewQuestions] = useState("");
  const [disruptCareerData, setDisruptCareerData] = useState("");
  let initialCount = 1;
  const [chatGPTData, setChatGPTData] = useState("");
  const [chatgptError, setChatgptError] = useState(false);
  const staticRandomEncodeDecodeString = useFeatureFlag(
    "staticRandomEncodeDecodeString"
  );
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const poweredByChatGPT = useFeatureFlag("Chat_GPT_Boost");
  const pageTitle =
    "Professionals Tech Innovation Software India | TrueSelfy Tech Relevance";
  const pageDescription =
    "Unlock the Future with TrueSelfy's Tech Innovation in India! Explore Top Tech Software for Professionals. Dive into Tech Relevance Today!";
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const textFieldRef = useRef(null);
  const [getInterviewQuestions, setGetInterviewQuestions] = useState(false);
  const apiTimeout = { timeout: 60000 };
  // const [value, setValue] = React.useState("figma");
  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const Refer_Link_Description_Write_up = useFeatureFlag(
    "Refer_Link_Description_Write_up"
  );
  const Hide_google_trends = useFeatureFlag("Hide_google_trends");
  const [shareLink, setShareLink] = useState(
    `${process.env.REACT_APP_BASE_URL}/signin`
  );
  const [targetElement, setTargetElement] = useState("relevance-result");
  const [relevanceTooltip, setRelevanceTooltip] = useState(false);
  const [run, setRun] = useState(false);
  const [insightsStep, setInsightsStep] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [skillLength, setSkillLength] = useState(0);
  const [userExist, setUserExist] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const questionForm = useSelector(
    (state) => state.QuestionFormReducer.questionForm || []
  );
  const [questions, setQuestions] = useState(questionForm);
  const [questionLoading, setQuestionLoading] = useState(false);
  let questionsApiCalled = false;

  const [videoLoaded, setVideoLoaded] = useState(false);
  const dispatch = useDispatch();
  const [closeJobForm, setCloseJobForm] = useState(false);
  const [subSkills, setSubSkills] = useState([]);
  const [errorAPI, setErrorAPI] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const fetchUserSession = async () => {
    try {
      const userAuthenticated = await getAuthState();
      if (userAuthenticated !== null) {
        setupAuthInterceptor(getAuthState);

        const userCreated = sessionStorage.getItem("userCreated");

        if (userCreated === null && isLoggedIn) {
          const payload = {
            email: getEmail(),
            registerTime: new Date(Date.now()).toUTCString(),
            lastLoginTime: new Date(Date.now()).toUTCString(),
            userOf: "relevance",
          };
          apis
            .POST({
              endpoint: "/create-user",
              payload: payload,
              options: { type: "json" },
            })
            .then((object) => {
              // console.log(object.data);
              setLoading(false);
              sessionStorage.setItem("userCreated", true);
            })
            .catch((error) => {
              sessionStorage.setItem("userCreated", true);
              // console.log(error);
              setLoading(false);
            });
        }
        // await setAuthState(getAuthState);
      } else {
        localStorage.clear();
        sessionStorage.clear();
        setLoading(false);
      }
      return getAuthState;
    } catch (error) {
      console.error("Error fetching user session:", error);
      setLoading(false);
    }
  };

  function getUserStatus() {
    const login = sessionStorage.getItem("isLoggedIn");
    if (login === "true") {
      return true;
    } else {
      return false;
    }
  }

  const isLoggedIn = getUserStatus();

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const PotentialSalaryIncraese = [
    "10",
    "20",
    "30",
    "15",
    "20",
    "30",
    "15",
    "30",
  ];

  const easeOfLearning = ["Easy", "Medium", "Hard"];
  //  check user logged or not

  useEffect(() => {
    fetchUserSession();
    if (!questionsApiCalled) {
      questionsApiCalled = true;
      setQuestionLoading(true);
      getQuestionForm(
        setQuestions,
        setQuestionLoading,
        dispatch,
        setQuestionForm
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getUserInfo();

    if (isLoggedIn) {
      setLoading(false);
      const element = document?.getElementById("enter-your-skills");
      element?.scrollIntoView();
    }
    const userType = sessionStorage.getItem("userType");
    if (userType === "user-auth") {
      const element = document?.getElementById("enter-your-skills");
      element?.scrollIntoView();
    }

    if (!isLoggedIn) {
      setRelevanceResult("");
      // setLoading(false);
      const pageLoading = setTimeout(() => {
        setLoading(false);
      }, 2500);
      return () => clearTimeout(pageLoading);
    }
  }, []);

  useEffect(() => {
    const element = document?.getElementById("enter-your-skills");
    element?.scrollIntoView();
  }, [isLoggedIn]);

  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const idTokenPayload = user.signInUserSession.idToken.payload;

      // Extract user information from idTokenPayload
      const email = idTokenPayload.email;
      setUserEmail(email);
      const name =
        idTokenPayload.name ||
        `${idTokenPayload.given_name || ""} ${
          idTokenPayload.family_name || ""
        }`.trim();

      // console.log("Email:", email);
      // console.log("User name:", name || "No name available");
    } catch (error) {
      console.error("Error getting user info:", error);
    }
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const introSteps = [
    {
      target: ".technical-validity-box",
      content:
        "💼 Unleash your potential! Our 'Technical Validity' feature validates the authenticity and proficiency of your top skill ✅, helping you build trust and credibility in your professional network.",
    },
    {
      target: ".skill-score",
      content:
        "Meet our Skill Score feature! It's like a crystal ball 🔮 for your skills, showing you their demand status in the industry with estimate year and how widely they're adopted by different companies. It's your guide to navigating the job market with confidence!",
    },
    {
      target: ".get-insights",
      content:
        "Ready for more? Hit 'Ask AI' for enterprise adoption 🏢, skill popularity 🎓, and potential salary boosts 💰. Let's uncover your path to success! 🌟",
    },
    {
      target: ".job-suggestion-box",
      content:
        "Unlock your next career step! Based on your IT experience, preferred office location, and chosen primary role, discover job opportunities that match your skills 🖥️, ideal work setting 🌍, and growth potential 🚀. Ready to find the perfect fit? Let's dive in! 🎯",
    },
  ];

  const InsightsSteps = [
    {
      target: ".skill-popularity",
      content:
        "📊 Skill popularity with ease! Our module rates skills on a scale of 1 to 10, showcasing their popularity among learners 🎓",
    },
    {
      target: ".sub-skills",
      content:
        "🔍 Dive into skill mastery! Explore detailed subskills for each skill, ranked by ease of learning 🧠 and relevance 🌟 to help you progress faster and smarter 🚀.",
    },
    {
      target: ".enterprise-adoption",
      content:
        "Discover 🏢 industry demand at a glance! Our Enterprise Adoption module rates skills on a scale of 0 to 100%, giving you a clear picture of their acceptance among businesses",
    },
    {
      target: ".lear-earn-recommendations",
      content: `Unlock 📚 learning opportunities and potential earnings! Our Learn & Earn Recommendations feature suggests how many skills you can acquire alongside your chosen skill. Click '${
        isMobile ? ":" : "learn more"
      }' to uncover these valuable suggestions and chart your path to success! 🌟`,
    },
    {
      target: ".interview-questions",
      content:
        "🎯 Ace your interviews with confidence! Discover the most asked questions 🗣️, tailored to your skillset and industry 🌍, helping you prepare like a pro 📚 and shine in your career path ✨.",
    },
    {
      target: ".disrupt-career-box",
      content:
        "🚀 Unlock transformative opportunities! Identify game-changing trends 🔮, essential skills 🛠️, and insights that can elevate your career to the next level 🌟 and keep you ahead of the curve 📈.",
    },
  ];

  function checkSkillType(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForCurrentSkill = 1;
    const minYearForOutdated = 4;
    if (year > currentYear) {
      return `Skill trending and in demand until ${year}`;
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return `Skill current and in demand until ${year}`;
    } else if (year >= currentYear - minYearForOutdated) {
      return `Skill getting outdated fast ${year}`;
    } else if (year <= currentYear - minYearDiff) {
      return `Skill obselete ${year}`;
    }
  }
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL + `/get-relevanceSkill?skills=java`,
        {}
      )
      .then((object) => {});
  }, []);

  useEffect((item) => {
    // setTimeout(() => {
    //   setRun(true);
    // }, 3000);
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Copied");
  };

  const messages = [
    "Hello, how can I assist you?",
    "I can provide information and answer questions.",
    "Just type your query, and I will do my best to help!",
  ];

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            // fontSize: "20px",
            color: "#232323",

            // textAlign: "center",
          }}
          // mb={{ xs: 2, md: 2, lg: 3, xl: 3 }}
          variant="h6"
        >
          <span
            style={{
              // fontSize: "20px",
              fontWeight: 700,
              color: "#26801E",
            }}
          >
            Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "#278C37",
        barSecondaryColor: "#9ad7a3",
        labelSuggestion,
        percentageColor: "#278C37",
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            // textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Technical skill in demand until ${year}`,
        barPrimaryColor: "#E9B112",
        barSecondaryColor: "#f5e7be",
        labelSuggestion,
        percentageColor: "#E9B112",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            // textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "#ffc107",
            }}
          >
            • Getting outdated
          </span>{" "}
          <span>: Upskill soon to stay ahead in the tech industry</span>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#C60015",
        barSecondaryColor: "#ffa7b0",
        labelSuggestion,
        percentageColor: "#C60015",
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            color: "#232323",
            mb: "47px",
            // textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "#028EDF",
        barSecondaryColor: "#b7e5ff",
        labelSuggestion,
        percentageColor: "#028EDF",
      };
    }
  }

  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (
        obj.expiryYear > maxExpiryYear &&
        obj.expiryYear !== null &&
        obj.expiryYear < new Date().getFullYear() + 25
      ) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      } else {
        if (obj?.expiryYear > new Date().getFullYear() + 100) {
          maxExpiryYear = parseFloat(obj?.estYear) + 10;
          maxExpiryYearObject = {
            ...obj,
            expiryYear: parseFloat(obj?.estYear) + 15,
          };
        } else if (obj?.estYear === null || obj.estYear === undefined) {
          maxExpiryYear = new Date().getFullYear() + 2;
          maxExpiryYearObject = {
            ...obj,
            expiryYear: new Date().getFullYear() + 2,
          };
        }
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }

  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson =
      secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(
      /"/g,
      staticRandomEncodeDecodeString
    );

    // Convert the string to UTF-8 encoding
    const utf8EncodedString = new TextEncoder().encode(newCustomStringJson);

    // Convert the UTF-8 array to a string in chunks
    let utf8String = "";
    for (let i = 0; i < utf8EncodedString.length; i += 10000) {
      utf8String += String.fromCharCode(
        ...utf8EncodedString.slice(i, i + 10000)
      );
    }

    const base64EncodedString = btoa(utf8String);
    return base64EncodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(
      new RegExp(secretEncodeDecodeString, "g"),
      ""
    );
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  // state for handle logout button
  const [openLogout, setOpenLogout] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallDevice = useMediaQuery("(max-width: 960px)");
  const [addSkill, setAddSkill] = useState(false);
  const [shouldAutoFocus, setShouldAutoFocus] = React.useState(false);

  const handleOpen = () => setOpenLogout(true);
  const handleClose = () => setOpenLogout(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // Perform additional logout actions if needed
    handleClose();
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const [showOTPForm, setShowOTPForm] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const shouldRunEffect = useRef(false);
  const cancelSkillToken = useRef(null);

  // tech relevance handler
  const onSubmit = async (data) => {
    // console.log(data, "data123");
    if (autoValue.length === 0) {
      methods.setError("primarySkill", {
        type: "required",
        message: `Please Enter a skill`,
      });
      setRelevanceResult("");
      return;
    }
    const userSession = await fetchUserSession();
    // console.log(
    //   DataDecryption(
    //     "VHJ1ZVNlbGZ5e3N6ZnJwbWJ1OW1lRFNAKiNEU3RvcFByaW1hcnlTa2lsbHN6ZnJwbWJ1OW1lRFNAKiNEUzpbe3N6ZnJwbWJ1OW1lRFNAKiNEU19pZHN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFM2NDM5NTU3NGJiNDI2NDRmYjk4ZThjOTRzemZycG1idTltZURTQCojRFMsc3pmcnBtYnU5bWVEU0AqI0RTbmFtZXN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFNPcmFjbGUgRVJQIEltcGxlbWVudGF0aW9uc3N6ZnJwbWJ1OW1lRFNAKiNEUyxzemZycG1idTltZURTQCojRFNlc3RZZWFyc3pmcnBtYnU5bWVEU0AqI0RTOnN6ZnJwbWJ1OW1lRFNAKiNEUzIwMjBzemZycG1idTltZURTQCojRFMsc3pmcnBtYnU5bWVEU0AqI0RTdHlwZXN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFN5c3pmcnBtYnU5bWVEU0AqI0RTLHN6ZnJwbWJ1OW1lRFNAKiNEU2NoYXRfZ3B0c3pmcnBtYnU5bWVEU0AqI0RTOnRydWUsc3pmcnBtYnU5bWVEU0AqI0RTZXhwaXJ5WWVhcnN6ZnJwbWJ1OW1lRFNAKiNEUzoxMDEwMjYwMTA5LHN6ZnJwbWJ1OW1lRFNAKiNEU3Njb3Jlc3pmcnBtYnU5bWVEU0AqI0RTOjIwMjA1MTYyMzh9XSxzemZycG1idTltZURTQCojRFN1c2VyRXhpc3RzemZycG1idTltZURTQCojRFM6dHJ1ZX1UcnVlU2VsZnk="
    //   ),
    //   "skill"
    // );
    // console.log(userSession, "userSession !== undefined");
    setJobSuggestionData("");
    setIsButtonClicked(false);

    if (
      (autoValue.length !== 0 && userSession !== undefined) ||
      userSession !== null
    ) {
      setUserExist(true);
      setLoadingButton(true);
      const userName = getUsername();
      setUserName(userName || "Your");
      setErrorAPI(false);
      setFormdata(data);
      setCheckSkill("");
      setGetInterviewQuestions(false);
      data.email = getEmail();
      data.userLocation = location;
      data.deviceInfo = deviceInfo;
      data.primarySkill = data.primarySkill
        .sort((a, b) => b.estYear - a.estYear)
        .slice(0, 5);
      setTargetElement("relevance-result");
      setSkillLength(methods.getValues("primarySkill")?.length);
      methodsJobSuggestion.reset();
      setShouldAutoFocus(false);
      // console.log("dataRelevance", data);
      try {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "/get-relevanceResult",
            DataEncryption(data),
            {
              options: {
                type: "json",
              },
            }
          )
          .then((object) => {
            const data = DataDecryption(object.data);
            // console.log("Data of relevance Result", data);
            setAddSkill(false);
            setRelevanceResult(data);
            setSkillList(data?.topPrimarySkill);
            // console.log(data?.topPrimarySkill, "relevance data");
            setUserExist(data?.userExist);
            setLoadingButton(false);
            setShouldAutoFocus(false);
            const element = document?.getElementById("relevance-result");
            // element?.scrollIntoView({ behavior: "smooth", block: "start" });
            textFieldRef.current = false;
            // if (data?.userExist === false) {
            const joyride = setTimeout(() => {
              setRun(true);
              setUserExist(data?.userExist);
            }, 1000);
            return () => clearTimeout(joyride);

            // } else {
            //   setRun(false);
            // }
            // methods.reset("");
            // setValue("skills", "");
          })
          .catch((error) => {
            if (
              error?.response?.status === 500 &&
              error?.response?.data?.message === "Email verification failed"
            ) {
              methods.setError("email", {
                type: "validation",
                message: "Email not valid !!",
              });
              setRelevanceResult("");
            }
            setErrorAPI(true);

            setLoadingButton(false);
          });
      } catch (error) {
        // console.log(error);
        setLoadingButton(false);
        setErrorAPI(true);
      }
    }
    ReactGA.event({
      category: "TechRelevance",
      action: "visited",
      label: "check-relevance",
    });
    ReactGA4.event({
      category: "TechRelevance",
      action: "visited",
      label: "check-relevance", // optional
    });
  };

  const [JobSuggestionData, setJobSuggestionData] = useState("");
  const [formVisible, setFormVisible] = useState(true);
  const [questionFormLoading, setQuestionFormLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleCheckNowClick = () => {
    setIsButtonClicked(true);
    setFormVisible(true);
    setCloseJobForm(false);
    setQuestionFormLoading(false);
  };

  const onQuestionSubmit = async (data) => {
    const userSession = await fetchUserSession();
    if (userSession !== undefined || userSession !== null) {
      data.primarySkill = autoValue;
      data.experience = getExperiences(data.experience);
      setQuestionFormLoading(true);
      // console.log("autoValue", autoValue);
      try {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "/get-job-suggestions",
            DataEncryption(data),
            {
              options: {
                type: "json",
              },
            }
          )
          .then((object) => {
            const data = DataDecryption(object.data);
            setJobSuggestionData(data?.JobSuggestionData);
            setFormVisible(false);
            setQuestionFormLoading(false);
            // console.log("Data of relevance", data);
          })
          .catch((error) => {
            if (
              error?.response?.status === 500 &&
              error?.response?.data?.message === "Email verification failed"
            ) {
              methods.setError("email", {
                type: "validation",
                message: "Email not valid !!",
              });
              // setRelevanceResult("");
            }
          });
      } catch (error) {
        // console.log(error);
        setQuestionFormLoading(false);
      }
    }
  };

  // chatgpt response handler
  const handleSendClick = async (item) => {
    // console.log(item, "itemitemitem");
    setInterviewQuestionsLoading(true);
    setChatgptError(false);
    setCheckSkill(item?.name);
    setSubSkills(item?.subSkill);
    setGetInterviewQuestions(false);
    setTargetElement("chat-gpt-result");
    if (userExist) {
      setInsightsStep(false);
    }
    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    try {
      setChatGptButton(true);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-chatgpt-response",
          DataEncryption({
            skillName: item?.name,
            resultId: relevanceResult?.relevanceResultId,
          }),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          setChatGptButton(false);
          const data = DataDecryption(object.data);
          let gptdata = JSON.parse(data?.result);
          // console.log("gptdata", gptdata);
          // ScrollDown();
          if (Object.keys(gptdata).length < 3) {
            setChatGPTData(Object.values(gptdata)[0]);
            // if (userExist === false) {
            // console.log(userExist, "userExist");

            ReactGA.event({
              category: "TechRelevance",
              action: "visited",
              label: "Ask-AI",
            });
            ReactGA4.event({
              category: "TechRelevance",
              action: "visited",
              label: "Ask-AI", // optional
            });
            const joyRideChatGpt = setTimeout(() => {
              setInsightsStep(true);
            }, 1000);
            return () => clearTimeout(joyRideChatGpt);
            // }
          } else {
            if (
              gptdata.length === 0 ||
              gptdata === null ||
              gptdata === undefined ||
              Object.keys(gptdata).length === 0
            ) {
              setChatgptError(true);
              setChatGPTData("");
              // console.log(gptdata, "gptdata");215
              toast.error("Something went wrong try again");
            } else {
              // setChatGPTData(gptdata);
              setChatGPTData(gptdata);
              // if (userExist === false) {
              // console.log(userExist, "userExist1");

              const joyRideChatGpt = setTimeout(() => {
                setInsightsStep(true);
              }, 1000);
              return () => clearTimeout(joyRideChatGpt);
              // } else {
              // setInsightsStep(false);
              // }

              // console.log(gptdata, "gptdata");
            }
          }

          // console.log(gptdata, "gptdata1");
        })
        .catch((error) => {
          setChatgptError(true);
          setChatGptButton(false);
          toast.error("Something went wrong try again");
        });

      // apis
      //   .POST({
      //     endpoint: "/get-interviewQuestion",
      //     payload: DataEncryption({
      //       skillName: item?.name,
      //       resultId: relevanceResult.relevanceResultId,
      //     }),
      //     options: { type: "json" },
      //   })
      //   .then((object) => {
      //     const data = DataDecryption(object.data);
      //     // console.log(data, "Interview-Question");
      //     setInterviewQuestions(data?.InterviewQuestion);
      //     setDisruptCareerData(data?.DisruptCareer);
      //     setInterviewQuestionsLoading(false);

      //     // console.log(data?.interviewQuestions, "interview questions");
      //   })
      //   .catch((error) => {});
      fetchInterviewQuestions(
        item,
        relevanceResult,
        DataEncryption,
        DataDecryption,
        setInterviewQuestions,
        setDisruptCareerData,
        setInterviewQuestionsLoading
      );
    } catch (error) {
      setChatgptError(true);
      setChatGptButton(false);
      toast.error("Something went wrong try again");
    }
  };

  // get interview questions
  const handleGetInterviewQuestions = () => {
    setGetInterviewQuestions(true);
    ScrollDown();
    // console.log({ primarySkill: skillList }, "payload");

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/get-interviewQuestion",
        DataEncryption({ primarySkill: skillList }),
        {
          headers: {
            "Content-Type": {
              json: "application/json",
              form: "application/x-www-form-urlencoded",
            },
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
          ...apiTimeout,
        }
      )
      .then((object) => {
        const data = DataDecryption(object.data);
        setInterviewQuestions(DataDecryption(object.data));
        // console.log(DataDecryption(object.data), "interview questions");
      })
      .catch((error) => {
        toast.error("Something went wrong, try again");
      });
  };

  const RetryGPT = (name) => {
    if (initialCount <= 3) {
      initialCount += 1;
      handleSendClick(name);
    } else {
      return;
    }
  };

  // scroll down hook
  useEffect(() => {
    const element = document?.getElementById(targetElement);
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: "smooth",
    // });
  }, [chatGptButton, relevanceResult]);
  const [topSkillValidity, setTopSkillValidity] = useState([]);
  // const [techRelevanceBtn, setTechRelevanceBtn] = useState(false);
  const [skillLoading, setSkillLoading] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [autoValue, setAutoValue] = useState(getValues("primarySkill") || []); // if dropdown open?

  // Tech Relevance form
  function TechRelevanceForm() {
    const getIconBySkillName = (skillName) => {
      const svgIconPath = `../../../assets/images/icons/${skillName}.svg`;
      const pngIconPath = `../../../assets/images/icons/${skillName}.png`;
      const darkIconPath = `../../../assets/images/dark-icons/${skillName}.png`;

      // Check if the SVG icon exists
      const svgExists = imageExists(svgIconPath);
      if (svgExists) {
        return svgIconPath;
      }
      // Check if the PNG icon exists
      const pngExists = imageExists(pngIconPath);
      if (pngExists) {
        return pngIconPath;
      }
      // Check if the dark SVG icon exists
      const darkExists = imageExists(darkIconPath);
      if (darkExists) {
        return darkIconPath;
      }

      // Return null if neither SVG nor PNG exists
      return null;
    };

    // Helper function to check if an image exists
    const imageExists = (url) => {
      const img = new Image();
      img.src = url;
      return img.complete && img.naturalHeight !== 0;
    };

    const {
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();

    const [open, setOpen] = useState(false); // if dropdown open?
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const filter = createFilterOptions({
      stringify: (option) => option.name,
    });

    const [duplicateMessage, setDuplicateMessage] = useState(
      "Duplicate skill found and removed"
    );
    const compareArrays = (a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    };

    useEffect(() => {
      // setValue("primarySkill", autoValue);
      if (methods.getValues("primarySkill")?.length !== skillLength) {
        setRelevanceResult("");
      }
      if (!shouldRunEffect.current) {
        shouldRunEffect.current = true;
        return;
      }

      if (
        autoValue.length != 0 &&
        !compareArrays(autoValue, topSkillValidity)
      ) {
        // console.log("component called");
        // console.log("getValues", getValues("primarySkill"));
        if (cancelSkillToken.current) {
          // console.log("cancelSkillToken", cancelSkillToken.current);
          setSkillLoading(true);

          // cancelSkillToken.current.cancel("Previous request cancelled");
        }

        try {
          cancelSkillToken.current = axios.CancelToken.source();
          setSkillLoading(true);
          setLoading(true);
          // setDuplicateError(false);
          setValue("primarySkill", autoValue);
          // setTechRelevanceBtn(true);
          axios
            .post(
              process.env.REACT_APP_API_BASE_URL + "/get-topSkillValidity",
              DataEncryption({
                primarySkill: autoValue,
              }),
              {
                cancelToken: cancelSkillToken.current.token,
                options: {
                  type: "json",
                },
              }
            )
            .then((object) => {
              const data = DataDecryption(object.data);
              // console.log(data, "Data skill relevance");
              // Find the index of the last duplicate skill
              let lastDuplicateIndex = -1;
              data.topPrimarySkill.forEach((skill, index) => {
                if (skill.isDuplicate) {
                  lastDuplicateIndex = index;
                }
              });

              if (lastDuplicateIndex !== -1) {
                setDuplicateError(true);
                const filterSkills = data.topPrimarySkill
                  .filter((item) => item.isDuplicate === false)
                  .sort((a, b) => b.estYear - a.estYear);
                shouldRunEffect.current = false;
                // data.topPrimarySkill.pop();
                let UserFormdata = {
                  email: getEmail(),
                  userLocation: location,
                  deviceInfo: deviceInfo,
                  primarySkill: filterSkills,
                };
                if (addSkill) {
                  onSubmit(UserFormdata);
                }
                setAutoValue(filterSkills);
                setTopSkillValidity(filterSkills);
                setValue("primarySkill", filterSkills);
                setSkillLoading(false);
                textFieldRef.current.focus();
                setShouldAutoFocus(true);
              } else {
                shouldRunEffect.current = false;
                setSkillLoading(false);
                setAutoValue(
                  data.topPrimarySkill.sort((a, b) => b.estYear - a.estYear)
                );
                setTopSkillValidity(data.topPrimarySkill);
                setValue("primarySkill", data.topPrimarySkill);
                let UserFormdata = {
                  email: getEmail(),
                  userLocation: location,
                  deviceInfo: deviceInfo,
                  primarySkill: data.topPrimarySkill.slice(0, 5),
                };
                textFieldRef.current.focus();
                setShouldAutoFocus(true);

                if (addSkill) {
                  onSubmit(UserFormdata);
                }
              }
              setLoading(false);

              const AlertDuplicate = setTimeout(() => {
                setDuplicateError(false);
              }, 3000);
              return () => clearTimeout(AlertDuplicate);
              // console.log(data, "Data relevance");
            });
        } catch (error) {
          setLoading(false);
          setSkillLoading(false);
          // setTechRelevanceBtn(false);
        }
      }
    }, [autoValue]);

    // useEffect(() => {
    //   setError("email", {
    //     type: "validation",
    //     message: "Email not valid !!",
    //   });
    // }, [setEmailError]);

    return (
      <React.Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems={"flex-end"}
          spacing={{ xs: 2, md: 3 }}
        >
          <Grid item xs={12} md={12} lg={6} sx={{ mb: 1.6 }}>
            <FormLabel
              sx={{
                fontFamily: "poppins",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#262655",
                paddingLeft: "14px",
              }}
              htmlFor="skills-autocomplete"
            >
              Skills
            </FormLabel>

            {autoValue?.length > 0 && <Legend />}

            <Controller
              name={"primarySkill"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={
                    noOptions
                      ? "No options found"
                      : "Start typing to get options"
                  }
                  {...field}
                  open={open}
                  onOpen={() => {
                    setNoOptions(false);
                    setOpen(true);
                    setData([]);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  multiple
                  autoComplete={true}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  value={autoValue}
                  onChange={(event, newValue) => {
                    if (
                      newValue.slice(-1)[0] &&
                      newValue.slice(-1)[0].inputValue != undefined
                    ) {
                      setAutoValue([
                        ...autoValue,
                        {
                          name: newValue.slice(-1)[0].inputValue,
                          other: true,
                        },
                      ]);
                      // setTopSkillValidity([]);
                    } else {
                      setAutoValue(newValue);
                      // setTopSkillValidity([]);
                    }
                    setData([]);
                  }}
                  filterSelectedOptions={true}
                  onInputChange={(event, value) => {
                    setNoOptions(true);
                    // console.log("input changed");
                    // setLoading(true);
                    // methods.clearErrors("primarySkill");
                    const trimmedValue = value?.trim();
                    if (trimmedValue) {
                      const encodedSkillName = Array.from(trimmedValue)
                        .map((char) => {
                          const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(
                            char
                          );
                          return isSpecialSymbol
                            ? encodeURIComponent(char)
                            : char;
                        })
                        .join("");
                      const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                      // console.log("data", data);
                      getSkillOptions(setData, endpoint, setLoading);
                    } else {
                      setLoading(false);
                    }
                  }}
                  id="skills-autocomplete"
                  options={data}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    // Check if input value matches any existing option
                    const isExisting = options.some(
                      (option) =>
                        params.inputValue.toLowerCase() ===
                        option.name.toLowerCase()
                    );
                    const paramsValue = params.inputValue.trim();
                    params.inputValue = params.inputValue.trim();
                    const filtered = filter(options, params);

                    // Check if input value matches any selected value
                    const selectedValue = getValues("primarySkill");
                    const filteredSelectedValue = selectedValue?.find(
                      (item) =>
                        item.name.toLowerCase() ===
                        params.inputValue.toLowerCase()
                    );

                    let filterData = [];
                    const secondSelectedSkills = getValues("primarySkill");

                    filterData = new Set(
                      secondSelectedSkills?.map((skill) =>
                        skill.name.toLowerCase()
                      )
                    );
                    // If input value is not empty and not existing as an option or selected value, add it to filtered options
                    if (
                      params.inputValue !== "" &&
                      !isExisting &&
                      !filteredSelectedValue &&
                      loading === false
                    ) {
                      if (!filterData.has(params.inputValue.toLowerCase())) {
                        // setError(questionName, {
                        //   type: "validate",
                        //   message: "Password is required",
                        // });
                        filtered.push({
                          inputValue: paramsValue,
                          name: `Add "${paramsValue}" Skill`,
                        });
                      }
                    }
                    return filtered;
                  }}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue.trim());
                    const parts = parse(option.name, matches);

                    return (
                      <li {...props} key={option.name}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: part.highlight ? "red" : "inherit",
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: { xs: "22px", lg: "22px !important" },
                        },
                      }}
                      inputRef={textFieldRef}
                      autoFocus={shouldAutoFocus}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={errors?.primarySkill ? true : false}
                      helperText={
                        <>{errors?.primarySkill && "Skills cannot be empty"}</>
                      }
                      label={"Select multiple options"}
                      id="skills-autocomplete"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const yearInfo = checkYearWithGpt(option);
                      // console.log(option, "option?.type");
                      const iconUrl = getIconBySkillName(option.name);
                      // Determine whether to use fixed size or normal size
                      const isFixedSize = value.length <= 10;

                      return (
                        <Chip
                          {...getTagProps({ index })}
                          key={option?._id}
                          label={option?.name}
                          sx={{
                            backgroundColor:
                              yearInfo?.barSecondaryColor ||
                              "rgba(0, 0, 0, 0.08)",
                            color:
                              yearInfo?.label === "Default"
                                ? "#000000"
                                : "#ffffff",
                            border:
                              `1px solid ${yearInfo?.color}` ||
                              "rgba(0, 0, 0, 0.87)",
                            width: "auto",
                            height: isFixedSize ? "45px !important" : "auto", // Fixed height for 10 or fewer chips, auto otherwise
                            fontSize: isFixedSize ? "15px !important" : "13px", // Fixed font size for 10 or fewer chips, default otherwise
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          avatar={
                            iconUrl ? (
                              <Avatar
                                src={iconUrl}
                                alt="Icon"
                                slotProps={{
                                  img: {
                                    loading: "lazy",
                                  },
                                }}
                                sx={{
                                  width: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  height: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  my: isFixedSize ? "0" : "3px",
                                  backgroundColor: "#ffffff",
                                  p: "5px",
                                }}
                              />
                            ) : (
                              <Avatar
                                alt="Icon"
                                slotProps={{
                                  img: {
                                    loading: "lazy",
                                  },
                                }}
                                sx={{
                                  fontSize: isFixedSize
                                    ? "13px !important"
                                    : "10px !important",
                                  width: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  height: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  my: isFixedSize ? "0" : "3px",
                                  backgroundColor: "#ffffff",
                                  p: "5px",
                                }}
                              >
                                {(() => {
                                  if (!option.name) return "";

                                  const words = option.name
                                    .split(" ")
                                    .filter((word) => word.length > 0);
                                  let abbreviation = "";

                                  const getFirstValidChar = (word) => {
                                    for (let i = 0; i < word.length; i++) {
                                      const char = word.charAt(i).toUpperCase();
                                      if (/^[a-zA-Z0-9]$/.test(char)) {
                                        return char;
                                      }
                                    }
                                    return "";
                                  };

                                  if (words.length === 1) {
                                    // Single word: Get the first valid character of the word
                                    abbreviation = getFirstValidChar(words[0]);
                                  } else if (words.length === 2) {
                                    // Two words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length === 3) {
                                    // Three words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length > 3) {
                                    // More than three words: Only use the first valid character of the first word
                                    abbreviation = getFirstValidChar(words[0]);
                                  }

                                  // Handle cases where abbreviation might still be empty (due to no valid characters)
                                  if (!abbreviation) {
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  }

                                  return abbreviation;
                                })()}
                              </Avatar>
                            )
                          }
                        />
                      );
                    })
                  }
                  disabled={skillLoading}
                />
              )}
            />
            {duplicateError && (
              <Box sx={{ py: 0.5, mt: 1 }}>
                <Alert severity="warning">{duplicateMessage}</Alert>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* <Grid
          container
          justifyContent="center"
          alignItems={"flex-end"}
          spacing={{ xs: 2, md: 3 }}
        >
          <GetQuestionForm questions={questions} />
        </Grid> */}
      </React.Fragment>
    );
  }

  var cancelToken = null;
  function getSkillOptions(setData, endpoint, setLoading) {
    setData([]);
    setLoading(true);
    if (cancelToken) {
      // console.log("cancelToken", cancelToken);
      cancelToken.cancel("Previous request cancelled");
    }
    // console.log("");
    try {
      cancelToken = axios.CancelToken.source();
      axios
        .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
          cancelToken: cancelToken.token,
        })
        .then((object) => {
          if (object?.status === 201) {
            var data = _.remove(DataDecryption(object.data), function (n) {
              return n.name !== "Other";
            });
            setData(data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Handle other errors here
            console.error("Error:", error);
          }
        });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setLoading(false);
    }
  }

  const handleTooltipClose = (key) => {
    // setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    setOpenToolTip({});
  };

  const handleTooltipOpen = (key) => {
    if (openTooltip[key] === true) {
      setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    } else {
      setOpenToolTip({});
      setOpenToolTip((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
      // border: 1px solid #008;
      // color: black;
      // background-color: #D6EFFF;
      // background-color: #FFF;
      // border-radius: 22px;
      // padding:"11px 34px !important";
      // filter: drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55));
  `);

  // custom tooltip component
  const CustomTooltipTitle = (data, index) => (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {" "}
        <IconButton
          onClick={() => {
            handleTooltipClose(index);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            maxWidth: 164,
            minWidth: 120,
            width: "100%",
            flex: 1,
            padding: 0.5,
          }}
        >
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              mb: "20px",
              textAlign: "center",
              padding: 0.5,
            }}
          >
            Ease of learning
          </Typography>
          <Box pt={{ xs: 3, sm: 0, md: 2, lg: 3, xl: 3 }}>
            <Typography
              color="primary"
              textAlign="center"
              sx={{
                fontFamily: "poppins",
                fontSize: { xs: "24px", md: "24px" },
                fontWeight: 700,
                textAlign: "center",
              }}
              pr={{ xs: 1, md: 0, lg: 0, xl: 0 }}
            >
              {data?.data?.easeOfLearning}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            flex: 0.1,
            borderColor: "#008",
          }}
        />
        <Box sx={{ p: 0.5, flex: 1 }}>
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              // mb: "15px",
              textAlign: "center",
              padding: 1,
            }}
          >
            Potential Salary Increase
          </Typography>
          <PotentialSalaryIncraeseChart
            value={data?.data?.potentialSalaryIncreasePercentage}
          />
        </Box>
      </Box>
    </React.Fragment>
  );

  // function to add subskills into main skill input

  function addSkillRelevance(skill) {
    const restSkills = skillList;
    setValue("primarySkill", [...restSkills, skill?.skillName]);
    const newSkils = [...autoValue, { name: skill?.skillName }];
    setAutoValue(newSkils);
    setAddSkill(true);
  }

  return (
    <ThemeProvider theme={relevenceTheme}>
      <React.Fragment>
        {/* Techrelevance Header section */}
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "center",
            lg: "space-between",
          }}
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 1,
            alignItems: "center",
          }}
        >
          {/* Header Section */}

          <Grid
            item
            xs={4.8}
            sm={5.5}
            order={{ sm: 1, md: 2 }}
            sx={{
              justifyContent: { sm: "flex-start", md: "flex-end" },
              display: "flex",
            }}
          >
            {/* {isLoggedIn ? (
              <Box>
                <Button variant="contained" endIcon={<LogoutIcon />} onClick={handleOpen}>
                  Logout
                </Button>
              </Box>
            ) : (
              ""
            )} */}
            {isSmallDevice ? (
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Grid item>
                {isLoggedIn && (
                  <Box
                    sx={{
                      pr: 3,
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: "55px",
                        textTransform: "capitalize",
                        backgroundImage:
                          "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                        border: "1px solid transparent",
                        transition: "all 0.3s linear",
                        "&:hover": {
                          border: "1px solid rgba(74,61,158,1)",
                          // background: "rgba(0, 158, 247, 0.10)",
                          background: "#FFF",
                          color: "rgba(108,82,229,1)",
                          transition: "all 0.3s linear",
                        },
                      }}
                      variant="contained"
                      endIcon={<LogoutIcon />}
                      onClick={handleOpen}
                    >
                      Logout
                    </Button>
                  </Box>
                )}
              </Grid>
            )}

            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        p: { xs: 1, md: 2, xl: 2 },
                        display: "flex",
                        justifyContent: "center",
                        maxWidth: { xs: "60px", md: "60px", lg: "80px" },
                      }}
                    >
                      <a href="/">
                        <img
                          src={"../../assets/images/TrueSelfy-logo.svg"}
                          alt="true-selfy"
                          loading="lazy"
                          style={{
                            maxWidth: `${isMobile ? "80px" : "80px"}`,
                            width: "100%",
                          }}
                        />
                      </a>
                    </Box>
                  </ListItem>
                  {isLoggedIn && (
                    <ListItem button onClick={handleOpen}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Drawer>
          </Grid>
          <Grid item xs={7.2} sm={6.5} order={{ sm: 2, md: 1 }}>
            <Box
              sx={{
                p: { xs: 1, md: 2, xl: 2 },
                display: "flex",
                justifyContent: "center",
                maxWidth: { xs: "60px", md: "60px", lg: "80px" },
              }}
            >
              <a href="/">
                <img
                  src={"../../assets/images/TrueSelfy-logo.svg"}
                  alt="true-selfy"
                  loading="lazy"
                  style={{
                    maxWidth: `${isMobile ? "80px" : "80px"}`,
                    width: "100%",
                  }}
                />
              </a>
            </Box>
          </Grid>

          <Modal
            open={openLogout}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="logout-modal-title" variant="h6" component="h2">
                  Confirm Logout
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to logout?
              </Typography>
              <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={handleLogout}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  No
                </Button>
              </Grid>
            </Box>
          </Modal>
        </Grid>

        {/* Techrelevance first Section */}
        <>
          {" "}
          {loading ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: {
                    xs: "68vh",
                    sm: "68vh",
                    md: "70vh",
                    lg: "78vh",
                  },
                  width: "100%",
                  overflow: "hidden",
                }}
                // position="fixed"
              >
                {/* <CircularProgress /> */}
                {isMobile ? (
                  <img
                    src={"../../../assets/images/loading-mobile.gif"}
                    alt="loading..."
                    loading="lazy"
                  />
                ) : (
                  <img
                    src={"../../../assets/images/loader3.gif"}
                    alt="loading..."
                    loading="lazy"
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              {!isLoggedIn ? (
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "rgba(35,28,79,0.4)",
                  }}
                >
                  {!videoLoaded && (
                    <img
                      src="../../assets/images/preload-image.png" // Path to your preload image
                      alt="Preload Image"
                      loading="lazy"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: -1, // Ensure preload image appears above video
                      }}
                    />
                  )}
                  <video
                    id="home-video"
                    autoPlay
                    loop
                    muted
                    onLoadedData={handleVideoLoaded} // Call handleVideoLoaded when video is loaded
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      zIndex: -1, // Ensure video appears behind preload image
                    }}
                  >
                    <source
                      src={`../../assets/images/backgroundVideo.mp4`}
                      type="video/mp4"
                    />
                  </video>
                  <Box
                    component="div"
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      p: {
                        xs: "75px 20px",
                        sm: "245px 75px",
                        md: "280px 75px",
                        lg: "280px 75px",
                        xl: "175px 75px",
                      },
                      borderRadius: { xs: "20px", md: "66px" },
                      // color: "red",
                      overflow: "hidden",
                    }}
                  >
                    {/* <Grid>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: { xs: "24px", md: "52px" },
                    lineHeight: { xs: "40px", md: "62px" },
                    color: "#FFC218",
                    mb: "48px",
                    textAlign: { xs: "center", md: "center" },
                    // wordBreak: "break-word",
                  }}
                  variant="h1"
                >
                  Check your TechRelevance
                </Typography>
              </Grid> */}
                    {/* <Grid container display={"flex"} alignItems={"center"}> */}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      order={{ xs: 2, sm: 2, md: 1 }}
                      // sx={{
                      //   py: { xs: 5, lg: "25vh" },
                      // }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: {
                            xs: "26px",
                            md: "38px",
                            lg: "38px",
                            xl: "48px",
                          },
                          fontWeight: 600,
                          lineHeight: "75px",
                          wordBreak: "break-word",
                          color: "#fff",
                          paddingBottom: isMobile ? "10px" : "0px",
                          textAlign: "center",
                        }}
                      >
                        Craft Your Career Journey with
                        {/* {poweredByChatGPT && "with AI!"} */}
                      </Typography>
                      <Typography
                        variant="h1"
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: {
                            xs: "38px",
                            md: "38px",
                            lg: "40px",
                            xl: "58px",
                          },
                          fontWeight: 700,
                          lineHeight: "85px",
                          color: "transparent",
                          backgroundImage:
                            "linear-gradient(to right, #2BB344 25%, #FFC218, #E0011C, #0BA2FF)",
                          WebkitBackgroundClip: "text",
                          backgroundClip: "text",
                        }}
                      >
                        Artificial Intelligence!
                      </Typography>

                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        {/* <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        display: { xs: "flex", sm: "none" },
                        flexDirection: "row",
                        justifyContent: "center",
                        pt: 2,
                      }}
                    >
                      What is TechRelevance 🤔 ?
                      <ClickAwayListener onClickAway={() => setRelevanceTooltip(false)}>
                        <Tooltip
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          onClose={() => setRelevanceTooltip(false)}
                          open={relevanceTooltip}
                          title={
                            <>
                              <Box>
                                <Typography sx={{ textAlign: "center" }}>
                                  TechRelevance helps you understand the lifespan of your skills in
                                  the ever-changing tech world. Stay ahead by knowing when to adapt.
                                </Typography>
                              </Box>
                            </>
                          }
                        >
                          <Box>
                            <InfoIcon
                              onClick={() => setRelevanceTooltip(true)}
                              sx={{
                                backgroundColor: "#000",
                                borderRadius: "100%",
                                color: "#ffc218",
                                ml: "5px",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </ClickAwayListener>
                    </Typography> */}
                        <Typography
                          sx={{
                            display: { xs: "flex", sm: "flex" },
                            fontFamily: "Montserrat",
                            fontSize: { xs: "18px", md: "24px", xl: "26px" },
                            fontWeight: 400,
                            lineHeight: "36px",
                            wordBreak: "break-word",
                            color: "#fff",
                            // color: { xs: "#000", md: "#fff" },
                            mb: "10px",
                            maxWidth: "1066px",
                            textAlign: "center",
                          }}
                        >
                          TechRelevance helps you understand the lifespan of
                          your skills in the ever-changing tech world. Stay
                          ahead by knowing when to adapt.
                        </Typography>
                      </Box>
                      <Box
                        py={2}
                        display={"flex"}
                        justifyContent={{ xs: "center", md: "center" }}
                      >
                        {!isLoggedIn ? (
                          <Button
                            variant="contained"
                            sx={{
                              // minWidth: { xs: "150px", md: "150px", lg: "150px", xl: "218px" },
                              borderRadius: "55px",
                              // background: `#FFF`,
                              color: "#fff",
                              fontSize: isMobile ? "16px" : "28px",
                              // backgroundColor: "#FFF !important",
                              backgroundImage:
                                "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                              textTransform: "capitalize",
                              border: "1px solid rgba(74,61,158,1)",
                              fontWeight: 600,
                              px: 8,
                              "&:hover": {
                                border: "1px solid rgba(74,61,158,1)",
                                // background: "rgba(0, 158, 247, 0.10)",
                                background: "#FFF",
                                color: "rgba(108,82,229,1)",
                                transition: "all 0.3s linear",
                              },
                            }}
                            onClick={() => {
                              if (!isLoggedIn) {
                                setOpen(true);
                              }
                              // console.log(isLoggedIn, "isLoggedIn");

                              // window.scrollTo({
                              //   top: document.documentElement.scrollHeight,
                              //   behavior: "smooth",
                              // });
                              // textFieldRef.current.focus();
                            }}
                          >
                            Start Now
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          pt: { xs: 0, xl: 2 },
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: { xs: "30px", sm: "42px", md: "52px" },
                          }}
                        >
                          <img
                            src="/assets/images/chat-gpt-logo.svg"
                            alt="powered-by-chat-gpt"
                            loading="lazy"
                            style={{
                              maxWidth: "52px",
                              width: "100%",
                            }}
                          />
                        </Box>
                        <Box sx={{ px: 1 }}>
                          <Typography sx={{ color: "#fff" }}>
                            Powered by ChatGPT
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* </Grid> */}
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {/* User Details Section */}
              {isLoggedIn ? (
                <Grid
                  container
                  alignItems={"flex-start"}
                  p={{
                    xs: "15px",
                    sm: "20px 50px",
                    md: "0px 50px",
                    lg: "30px 40px 0px 40px",
                    xl: "30px 70px 0px 70px",
                  }}
                  // spacing={{ xs: 4, md: 5, lg: 5, xl: 7 }}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    xs={12}
                    id={"enter-your-skills"}
                    sx={{ minHeight: "75vh" }}
                  >
                    <Typography
                      // color={"primary"}
                      sx={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        fontSize: { xs: "24px", sm: "30px", md: "45px" },
                        textAlign: "center",
                        // lineHeight: { xs: "40px", md: "62px" },
                        // color: "#fff",
                        mb: {
                          xs: "18px",
                          sm: "22px",
                          md: "18px",
                          lg: "37px",
                          xl: "37px",
                        },
                        mt: { xs: 0, sm: 0, md: "18px", lg: "0px" },
                        wordBreak: "break-word",
                        color: "#020D17",
                      }}
                    >
                      Enter Your Details
                    </Typography>
                    <React.Fragment>
                      <FormProvider {...methods}>
                        <form
                          id="tech-relevance"
                          onSubmit={methods.handleSubmit(onSubmit)}
                        >
                          <TechRelevanceForm />
                          <Grid>
                            <Box py={4}>
                              {loadingButton ? (
                                <Grid item xs={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      my: 5,
                                    }}
                                  >
                                    <Loading />
                                  </Box>
                                </Grid>
                              ) : (
                                <Button
                                  id={"check-relevance-button"}
                                  type="submit"
                                  form="tech-relevance"
                                  // variant="contained"
                                  sx={{
                                    maxWidth: "333px",
                                    width: "100%",
                                    display: "block",
                                    m: "0px auto",
                                    color: "#FFF",
                                    P: "12px",
                                    fontSize: "24px",
                                    fontWeight: 600,
                                    borderRadius: "55px",
                                    textTransform: "capitalize",
                                    backgroundImage:
                                      "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                    border: "1px solid transparent",
                                    transition: "all 0.3s linear",
                                    "&:hover": {
                                      border: "1px solid rgba(74,61,158,1)",
                                      // background: "rgba(0, 158, 247, 0.10)",
                                      background: "#FFF",
                                      color: "rgba(108,82,229,1)",
                                      transition: "all 0.3s linear",
                                    },
                                  }}
                                  loading={loadingButton}
                                  disabled={skillLoading}
                                >
                                  {" "}
                                  Check TechRelevance
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </form>
                      </FormProvider>
                      {errorAPI && (
                        <Box>
                          <ErrorComponent />
                        </Box>
                      )}
                    </React.Fragment>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <SignInModal setOpen={setOpen} open={open} userData={userData} />
              {/* Tech relevance Section */}
              {relevanceResult?.length !== 0 &&
              relevanceResult !== undefined &&
              !loadingButton &&
              isLoggedIn ? (
                <>
                  <Joyride
                    steps={introSteps}
                    run={run}
                    showSkipButton
                    continuous
                    disableCloseOnEsc
                    disableOverlayClose
                    disableScrolling
                  />
                  <motion.div
                    initial={{ opacity: 0.6, y: 200 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, y: -200 }}
                    transition={{ duration: 1 }}
                  >
                    <Grid
                      container
                      p={{
                        xs: "15px",
                        sm: "20px 50px",
                        md: "0px 50px",
                        lg: "30px 40px 0px 40px",
                        xl: "30px 70px 0px 70px",
                      }}
                    >
                      <Grid item xs={12} id={"relevance-result"}>
                        <Typography
                          // color={"primary"}
                          sx={{
                            fontFamily: "poppins",
                            fontSize: { xs: "30px", md: "45px" },
                            textAlign: "center",
                            mb: "37px",
                            mt: { xs: "64px", sm: 10 },
                            fontWeight: 600,
                            wordBreak: "break-word",
                            color: "#020D17",
                          }}
                        >
                          {UserName.length !== 0
                            ? UserName === "Your"
                              ? `${getFirstWord(UserName)} TechRelevance`
                              : `${getFirstWord(UserName)}'s TechRelevance`
                            : ""}
                        </Typography>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 5 }}
                          rowSpacing={1}
                        >
                          <Grid item xs={12} md={6}>
                            <Box
                              component="div"
                              // px={{ xs: 3, md: 10, lg: 10 }}
                              // py={{ xs: 3, md: 10, lg: 10 }}
                              sx={{
                                p: {
                                  xs: "25px",
                                  md: "30px",
                                  lg: "30px",
                                  xl: "60px",
                                },
                                border: "1px solid rgba(102,82,228,0.25)",
                                borderRadius: "39px",
                                boxShadow: "0px 0px 8px 0px #D9D9FF",
                                height: "100%",
                                boxSizing: "inherit",
                              }}
                            >
                              <Box
                                className="technical-validity-box"
                                sx={{
                                  background: "#fff",
                                  display: "flex",
                                  flexDirection: { xs: "column", lg: "row" },
                                  alignItems: { xs: "center", lg: "unset" },
                                }}
                              >
                                <Box
                                  sx={{
                                    maxWidth: { xs: "188px", xl: "188px" },
                                    width: "100%",
                                    height: "auto",
                                    // m: "0px auto",
                                    mb: "36px",
                                    displays: "auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/assets/images/timer2.gif"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      maxWidth: "192px",
                                    }}
                                    alt="check-icon"
                                    loading="lazy"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    color="primary"
                                    sx={{
                                      fontFamily: "poppins",
                                      fontSize: "32px",
                                      fontWeight: 700,
                                      textAlign: {
                                        xs: "center",
                                        md: "center",
                                        lg: "start",
                                        xl: "start",
                                      },
                                      color: "#020D17",
                                    }}
                                  >
                                    Technical validity until
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "poppins",
                                      fontSize: "65px",
                                      fontWeight: 700,
                                      textAlign: {
                                        xs: "center",
                                        md: "center",
                                        lg: "start",
                                        xl: "start",
                                      },
                                      color: "#6652E4",
                                      lineHeight: "1.1",
                                    }}
                                  >
                                    {
                                      getTopSkill(
                                        relevanceResult?.topPrimarySkill
                                      ).maxExpiryYear
                                    }
                                    <Typography variant="subtitle2">
                                      (approximately)
                                    </Typography>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "poppins",
                                      // fontSize: "65px",
                                      fontWeight: 700,
                                      textAlign: {
                                        xs: "center",
                                        md: "center",
                                        lg: "start",
                                        xl: "start",
                                      },
                                      lineHeight: "1.1",
                                      // color: "#6652E4",
                                    }}
                                  >
                                    {
                                      getTopSkill(
                                        relevanceResult?.topPrimarySkill
                                      )?.labelSuggestion
                                    }
                                  </Typography>
                                </Box>
                              </Box>

                              {/* <Box
                        component="div"
                        sx={{
                          height: "1.5px",
                          background: "#000000",
                          maxWidth: "193px",
                          width: "100%",
                          m: "0px auto",
                          mt: "15px",
                        }}
                      ></Box> */}

                              {/* Form rendering logic */}
                              {!JobSuggestionData && !isMobile && (
                                <FormProvider {...methodsJobSuggestion}>
                                  <form
                                    id="question-tech-relevance"
                                    onSubmit={methodsJobSuggestion.handleSubmit(
                                      onQuestionSubmit
                                    )}
                                  >
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems={"flex-end"}
                                      spacing={{ xs: 2, md: 1 }}
                                      sx={{
                                        my: 2,
                                        pt: 2,
                                      }}
                                    >
                                      <GetQuestionForm questions={questions} />
                                      <Grid item xs={12}>
                                        <Box py={4}>
                                          {questionFormLoading ? (
                                            <Grid item xs={12}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  my: 5,
                                                }}
                                              >
                                                <CircularProgress />
                                              </Box>
                                            </Grid>
                                          ) : (
                                            <Button
                                              id={"question-form-btn"}
                                              type="submit"
                                              form="question-tech-relevance"
                                              // variant="contained"
                                              sx={{
                                                maxWidth: "333px",
                                                width: "100%",
                                                display: "block",
                                                m: "0px auto",
                                                color: "#FFF",
                                                P: "12px",
                                                fontSize: "24px",
                                                fontWeight: 600,
                                                borderRadius: "55px",
                                                textTransform: "capitalize",
                                                backgroundImage:
                                                  "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                                border: "1px solid transparent",
                                                transition: "all 0.3s linear",
                                                "&:hover": {
                                                  border:
                                                    "1px solid rgba(74,61,158,1)",
                                                  // background: "rgba(0, 158, 247, 0.10)",
                                                  background: "#FFF",
                                                  color: "rgba(108,82,229,1)",
                                                  transition: "all 0.3s linear",
                                                },
                                              }}
                                              size="small"
                                              loading={questionFormLoading}
                                              // disabled={skillLoading}
                                            >
                                              Submit
                                            </Button>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </form>
                                </FormProvider>
                              )}

                              {/* JobSuggestionDataCard displayed only after form submission */}
                              {JobSuggestionData && !isMobile && (
                                <Box>
                                  <Grid item xs={12}>
                                    {!isMobile || !formVisible ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        <JobSuggestionDataCard
                                          data={JobSuggestionData}
                                        />
                                        <Button
                                          sx={{
                                            // maxWidth: "370px",
                                            // width: isMobile ? "100%" : "100%",
                                            // display: "block",
                                            // m: "0px auto",
                                            color: "#FFF",
                                            mt: 4,
                                            px: "26px",
                                            fontSize: isMobile
                                              ? "16px"
                                              : "18px",
                                            fontWeight: 600,
                                            borderRadius: "55px",
                                            textAlign: "center",
                                            textTransform: "capitalize",
                                            background: "#2BB344",
                                            backgroundColor: "#2BB344",
                                            border: "1px solid #2BB344",

                                            "&:hover": {
                                              color: "#2BB344",
                                              background: "#fff",
                                              border: "1px solid #2BB344",
                                              transition: "all 0.3s linear",
                                            },
                                          }}
                                          size={"large"}
                                          onClick={() => {
                                            setJobSuggestionData("");
                                            methodsJobSuggestion.reset();
                                          }}
                                        >
                                          Check Again
                                        </Button>
                                      </Box>
                                    ) : null}
                                  </Grid>
                                </Box>
                              )}

                              {!isMobile && (
                                <Grid container>
                                  <Box
                                    sx={{
                                      padding: "20px",
                                      backgroundColor: "rgba(43,179,68,15%)",
                                      borderRadius: "15px",
                                      mt: 4,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundImage:
                                        "linear-gradient(to right, rgba(43,179,68,10%), rgba(43,179,68,50%) )",
                                      transition: "transform 0.3s",
                                      "&:hover": {
                                        transform: "scale(1.02)",
                                      },
                                    }}
                                  >
                                    <Grid item xs={12} md={8} lg={12} xl={6}>
                                      <Box
                                        sx={{
                                          gap: 4,
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              color: "#2BB344",
                                              fontFamily: "poppins",
                                              fontWeight: 700,
                                            }}
                                            variant="h2"
                                          >
                                            Are you underpaid?
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            Determine your potential salary by
                                            calculating TechWorth
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            // pb: { xs: 2, sm: 2, md: 2, lg: 0 },
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              // maxWidth: "370px",
                                              // width: isMobile ? "100%" : "100%",
                                              // display: "block",
                                              // m: "0px auto",
                                              color: "#FFF",
                                              px: "26px",
                                              fontSize: isMobile
                                                ? "16px"
                                                : "18px",
                                              fontWeight: 600,
                                              borderRadius: "55px",
                                              textAlign: "center",
                                              textTransform: "capitalize",
                                              background: "#2BB344",
                                              backgroundColor: "#2BB344",
                                              border: "1px solid #2BB344",
                                              transition: "all 0.3s linear",
                                              "&:hover": {
                                                color: "#2BB344",
                                                background: "#fff",
                                                border: "1px solid #2BB344",
                                                transition: "all 0.3s linear",
                                              },
                                            }}
                                            size={"small"}
                                            component={"a"}
                                            href={
                                              "https://techworth.trueselfy.com/"
                                            }
                                          >
                                            Check TechWorth
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={12} xl={6}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "flex-end",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxWidth: "290px",
                                            height: "auto",
                                            width: "100%",
                                          }}
                                          alt="under-paid"
                                          src="../../assets/images/under-paid.png"
                                        />
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Grid>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Box
                              component="div"
                              sx={{
                                p: { xs: "25px", md: "35px" },
                                border: "2px solid rgba(102,82,228,0.25)",
                                borderRadius: "39px",
                                boxShadow: "0px 0px 8px 0px #D9D9FF",
                                height: "100%",
                                boxSizing: "inherit",
                              }}
                            >
                              <Grid
                                container
                                // direction="row"
                                alignItems={"center"}
                                sx={{ mb: isMobile ? "5px" : "0px" }}
                                justifyContent={{
                                  xs: "center",
                                  lg: "space-between",
                                }}
                              >
                                <Grid item xs={12} lg={8}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      alignItems: "center",
                                      justifyContent: {
                                        xs: "center",
                                        lg: "flex-start",
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        // mr: { xs: 0, md: "20px" },
                                        maxWidth: { xs: "46px", md: "58px" },
                                        height: "100%",
                                        width: "100%",
                                      }}
                                    >
                                      <img
                                        src="/assets/images/skill-score-relevance-01.svg"
                                        width={"100%"}
                                        height="auto"
                                        alt="skill-score"
                                        loading="lazy"
                                      />
                                    </Box>

                                    <Typography
                                      sx={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        fontSize: { xs: "28px", md: "32px" },
                                        letterSpacing: "0.5px",
                                        color: "#262655",
                                      }}
                                      variant="h5"
                                    >
                                      Skill score
                                    </Typography>
                                  </Box>
                                </Grid>
                                {!isMobile && (
                                  <Grid item xs={0} lg={4}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontFamily: "poppins",
                                            fontSize: {
                                              sm: "18px",
                                              xl: "24px",
                                            },
                                            fontWeight: 700,
                                            textAlign: {
                                              xs: "center",
                                              md: "center",
                                              lg: "center",
                                            },
                                            wordBreak: "break-word",
                                            color: "#262655",
                                            mr: "5px",
                                          }}
                                        >
                                          Career Insights by ChatGPT
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <img
                                          style={{ maxWidth: "40px" }}
                                          src="/assets/images/chat-gpt-logo.svg"
                                          alt="powered-by-chat-gpt"
                                          loading="lazy"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                                {autoValue?.length >= 5 && (
                                  <Grid item xs={12}>
                                    <Box sx={{ py: 1 }}>
                                      <Alert
                                        severity="success"
                                        sx={{ alignItems: "center" }}
                                      >
                                        Displaying the top 5 skills based on the
                                        most recent validity year.
                                      </Alert>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>

                              {relevanceResult?.topPrimarySkill?.map(
                                (item, index) => {
                                  // if (item?.expiryYear > new Date().getFullYear() + 20) {
                                  //   delete item?.expiryYear;
                                  //   item.expiryYear = item?.estYear + 15;
                                  // } else if (item?.expiryYear === null) {
                                  //   delete item?.expiryYear;
                                  //   item.expiryYear = new Date().getFullYear() + 2;
                                  // }
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: { xs: 2, lg: 2, xl: 15 },
                                        alignItems: {
                                          xs: "center",
                                          sm: "flex-end",
                                          md: "center",
                                          lg: "flex-end",
                                          xl: "flex-end",
                                        },
                                        justifyContent: "space-between",
                                        my: { xs: "20px", md: "30px" },
                                      }}
                                      flexDirection={{
                                        xs: "column",
                                        sm: "row",
                                        md: "column",
                                        lg: "row",
                                        xl: "row",
                                      }}
                                    >
                                      <Box
                                        flexGrow={1}
                                        sx={{
                                          width: "100%",
                                          background: "#fff",
                                          maxWidth: "700px",
                                        }}
                                        className={"skill-score"}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "poppins",
                                            fontSize: "22px",
                                            fontWeight: 700,
                                            display: "block",
                                            mb: "6px",
                                            mr: "20px",
                                            // wordBreak: "break-all",
                                          }}
                                        >
                                          {item?.name}
                                        </Typography>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: 2,
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              color: "#262655",
                                            }}
                                          >
                                            {item.type === "y"
                                              ? checkYear(item?.expiryYear)
                                                  .label
                                              : checkSkillType(
                                                  item?.expiryYear
                                                )}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "24px",
                                              fontWeight: 700,
                                            }}
                                            color={
                                              checkYear(item?.expiryYear)
                                                ?.percentageColor
                                            }
                                          >
                                            {item?.score <= 0
                                              ? "20"
                                              : item?.score > 100
                                              ? "80"
                                              : Math.round(item?.score)}
                                            %
                                          </Typography>
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                          <LinearProgress
                                            sx={{
                                              height: "10px",
                                              borderRadius: "20px",
                                              "& .MuiLinearProgress-bar": {
                                                backgroundColor: checkYear(
                                                  item?.expiryYear
                                                ).barPrimaryColor,
                                              },
                                              backgroundColor: checkYear(
                                                item?.expiryYear
                                              ).barSecondaryColor,
                                            }}
                                            color="error"
                                            variant="determinate"
                                            value={
                                              item?.score <= 0
                                                ? 20
                                                : item?.score > 100
                                                ? 80
                                                : Math.round(item?.score)
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      {isMobile && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontFamily: "poppins",
                                                fontSize: "18px",
                                                fontWeight: 700,
                                                textAlign: {
                                                  xs: "center",
                                                  md: "center",
                                                  lg: "center",
                                                },
                                                wordBreak: "break-word",
                                                color: "#262655",
                                                mr: "5px",
                                              }}
                                            >
                                              Career Insights by ChatGPT
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <img
                                              style={{ maxWidth: "30px" }}
                                              src="/assets/images/chat-gpt-logo.svg"
                                              alt="powered-by-chat-gpt"
                                              loading="lazy"
                                            />
                                          </Box>
                                        </Box>
                                        // <>
                                        //   <Typography
                                        //     sx={{
                                        //       fontFamily: "poppins",
                                        //       // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                        //       // backgroundClip: "text",
                                        //       // WebkitBackgroundClip: "text",
                                        //       // WebkitTextFillColor: "transparent",
                                        //       fontSize: "18px",
                                        //       fontWeight: 700,
                                        //       color: "#262655",

                                        //       textAlign: "center",
                                        //     }}
                                        //   // className="typing-demo"
                                        //   >
                                        //     Career Insights by ChatGPT
                                        //   </Typography>
                                        // </>
                                      )}

                                      {poweredByChatGPT && (
                                        // <Box>

                                        <Button
                                          sx={{
                                            width: "100%",
                                            maxWidth: "200px",
                                            color: "#fff",
                                            alignItems: "center",
                                            border:
                                              "1px solid rgba(74,61,158,1)",
                                            justifyContent: "space-evenly",
                                            padding: "7px 12px",
                                            borderRadius: "27px",
                                            background:
                                              "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1))",
                                            transition: "all 0.3s linear",
                                          }}
                                          className="get-insights btn-anim" // Add the class here
                                          endIcon={
                                            <Box>
                                              <EmojiIcon
                                                lottieFiles={[ai]}
                                                interval={4000}
                                              />
                                            </Box>
                                          }
                                          startIcon={
                                            <Box>
                                              <EmojiIcon
                                                lottieFiles={[ai]}
                                                interval={4000}
                                              />
                                            </Box>
                                          }
                                          onClick={() => handleSendClick(item)}
                                        >
                                          Ask AI
                                        </Button>

                                        // </Box>
                                      )}
                                    </Box>
                                  );
                                }
                              )}
                            </Box>
                          </Grid>

                          {isMobile && (
                            <Grid item xs={12} md={6}>
                              <Box
                                component="div"
                                // px={{ xs: 3, md: 10, lg: 10 }}
                                // py={{ xs: 3, md: 10, lg: 10 }}
                                sx={{
                                  p: {
                                    xs: "20px",
                                    md: "30px",
                                    lg: "30px",
                                    xl: "60px",
                                  },
                                  border: "1px solid rgba(102,82,228,0.25)",
                                  borderRadius: "39px",
                                  boxShadow: "0px 0px 8px 0px #D9D9FF",
                                  height: "100%",
                                  boxSizing: "inherit",
                                }}
                              >
                                {!isButtonClicked && isMobile && (
                                  <Grid
                                    container
                                    sx={{
                                      // p: { xs: "10px, 10px", lg: "10px 45px", xl: "10px 45px" },
                                      py: {
                                        xs: "20px",
                                        md: "10px",
                                        lg: "10px",
                                      },
                                      px: {
                                        xs: "20px",
                                        md: "45px",
                                        xl: "45px",
                                      },
                                      background: "rgba(43,179,68,15%)",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      lg={8}
                                      xl={7}
                                      className="job-suggestion-box"
                                    >
                                      <Box sx={{ mb: "15px" }}>
                                        <Typography
                                          sx={{
                                            textAlign: {
                                              xs: "center",
                                              lg: "left",
                                            },
                                            color: "#2BB344",
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            fontSize: {
                                              xs: "20px",
                                              sm: "24px",
                                              lg: "24px",
                                              xl: "32px",
                                            },
                                          }}
                                        >
                                          Are you open to new opportunities?
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      lg={4}
                                      xl={5}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box>
                                        <Button
                                          sx={{
                                            // maxWidth: "370px",
                                            // width: isMobile ? "100%" : "100%",
                                            // display: "block",
                                            // m: "0px auto",
                                            color: "#FFF",
                                            px: "26px",
                                            fontSize: isMobile
                                              ? "16px"
                                              : "18px",
                                            fontWeight: 600,
                                            borderRadius: "55px",
                                            textAlign: "center",
                                            textTransform: "capitalize",
                                            background: "#2BB344",
                                            backgroundColor: "#2BB344",
                                            border: "1px solid #2BB344",

                                            "&:hover": {
                                              color: "#2BB344",
                                              background: "#fff",
                                              border: "1px solid #2BB344",
                                              transition: "all 0.3s linear",
                                            },
                                          }}
                                          size={"large"}
                                          onClick={handleCheckNowClick}
                                        >
                                          Check Now
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}

                                {/* Form rendering logic */}
                                {/* Mobile job suggestion box */}
                                {!JobSuggestionData &&
                                  closeJobForm === false &&
                                  (isButtonClicked || !isMobile) && (
                                    <FormProvider {...methodsJobSuggestion}>
                                      <form
                                        id="question-tech-relevance"
                                        onSubmit={methodsJobSuggestion.handleSubmit(
                                          onQuestionSubmit
                                        )}
                                      >
                                        <Grid
                                          container
                                          justifyContent="center"
                                          alignItems={"flex-end"}
                                          spacing={{ xs: 2, md: 1 }}
                                          sx={{
                                            my: 2,
                                            pt: 2,
                                          }}
                                        >
                                          <GetQuestionForm
                                            questions={questions}
                                          />
                                          <Grid item xs={12}>
                                            <Box py={4}>
                                              {questionFormLoading ? (
                                                <Grid item xs={12}>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      my: 5,
                                                    }}
                                                  >
                                                    <CircularProgress />
                                                  </Box>
                                                </Grid>
                                              ) : (
                                                <Button
                                                  id={"question-form-btn"}
                                                  type="submit"
                                                  form="question-tech-relevance"
                                                  // variant="contained"
                                                  sx={{
                                                    maxWidth: "333px",
                                                    width: "100%",
                                                    display: "block",
                                                    m: "0px auto",
                                                    color: "#FFF",
                                                    P: "12px",
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    borderRadius: "55px",
                                                    textTransform: "capitalize",
                                                    backgroundImage:
                                                      "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                                    border:
                                                      "1px solid transparent",
                                                    transition:
                                                      "all 0.3s linear",
                                                    "&:hover": {
                                                      border:
                                                        "1px solid rgba(74,61,158,1)",
                                                      // background: "rgba(0, 158, 247, 0.10)",
                                                      background: "#FFF",
                                                      color:
                                                        "rgba(108,82,229,1)",
                                                      transition:
                                                        "all 0.3s linear",
                                                    },
                                                  }}
                                                  size="small"
                                                  loading={questionFormLoading}
                                                  // disabled={skillLoading}
                                                >
                                                  Submit
                                                </Button>
                                              )}
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </form>
                                    </FormProvider>
                                  )}

                                {/* JobSuggestionDataCard displayed only after form submission */}
                                {JobSuggestionData && (
                                  <Box>
                                    <Grid item xs={12}>
                                      {isMobile && !formVisible ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <JobSuggestionDataCard
                                            data={JobSuggestionData}
                                          />
                                          <Button
                                            sx={{
                                              // maxWidth: "370px",
                                              // width: isMobile ? "100%" : "100%",
                                              // display: "block",
                                              // m: "0px auto",
                                              color: "#FFF",
                                              mt: 2,
                                              px: "26px",
                                              fontSize: isMobile
                                                ? "16px"
                                                : "18px",
                                              fontWeight: 600,
                                              borderRadius: "55px",
                                              textAlign: "center",
                                              textTransform: "capitalize",
                                              background: "#2BB344",
                                              backgroundColor: "#2BB344",
                                              border: "1px solid #2BB344",

                                              "&:hover": {
                                                color: "#2BB344",
                                                background: "#fff",
                                                border: "1px solid #2BB344",
                                                transition: "all 0.3s linear",
                                              },
                                            }}
                                            size={"large"}
                                            onClick={() => {
                                              setJobSuggestionData("");
                                              methodsJobSuggestion.reset();
                                            }}
                                          >
                                            Check Again
                                          </Button>
                                          <Box
                                            sx={{ mt: 2 }}
                                            onClick={() => {
                                              setJobSuggestionData("");
                                              setIsButtonClicked(false);
                                              methodsJobSuggestion.reset();
                                            }}
                                          >
                                            <Button
                                              sx={{
                                                // maxWidth: "370px",
                                                // width: isMobile ? "100%" : "100%",
                                                // display: "block",
                                                // m: "0px auto",
                                                color: "#FFF",
                                                px: "26px",
                                                fontSize: isMobile
                                                  ? "16px"
                                                  : "18px",
                                                fontWeight: 600,
                                                borderRadius: "55px",
                                                textAlign: "center",
                                                textTransform: "capitalize",
                                                background: "#fc0320",
                                                backgroundColor: "#fc0320",
                                                border: "1px solid #fc0320",

                                                "&:hover": {
                                                  color: "#fc0320",
                                                  background: "#fff",
                                                  border: "1px solid #fc0320",
                                                  transition: "all 0.3s linear",
                                                },
                                              }}
                                            >
                                              {" "}
                                              Close
                                            </Button>
                                            {/* <CancelIcon sx={{ color: "red" }} /> */}
                                          </Box>
                                        </Box>
                                      ) : null}
                                    </Grid>
                                  </Box>
                                )}

                                <Grid container>
                                  <Box
                                    sx={{
                                      padding: "20px",
                                      backgroundColor: "rgba(43,179,68,15%)",
                                      borderRadius: "15px",
                                      mt: 4,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundImage:
                                        "linear-gradient(to right, rgba(43,179,68,10%), rgba(43,179,68,50%) )",
                                      transition: "transform 0.3s",
                                      "&:hover": {
                                        transform: "scale(1.02)",
                                      },
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Grid item xs={12} md={4} lg={12} xl={6}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "flex-end",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxWidth: "290px",
                                            height: "auto",
                                            width: "100%",
                                          }}
                                          alt="under-paid"
                                          src="../../assets/images/under-paid.png"
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={12} xl={6}>
                                      <Box
                                        sx={{
                                          gap: 4,
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              color: "#2BB344",
                                              fontFamily: "poppins",
                                              fontWeight: 700,
                                            }}
                                            variant="h2"
                                          >
                                            Are you underpaid?
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            Determine your potential salary by
                                            calculating TechWorth
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            // pb: { xs: 2, sm: 2, md: 2, lg: 0 },
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              // maxWidth: "370px",
                                              // width: isMobile ? "100%" : "100%",
                                              // display: "block",
                                              // m: "0px auto",
                                              color: "#FFF",
                                              px: "26px",
                                              fontSize: isMobile
                                                ? "16px"
                                                : "18px",
                                              fontWeight: 600,
                                              borderRadius: "55px",
                                              textAlign: "center",
                                              textTransform: "capitalize",
                                              background: "#2BB344",
                                              backgroundColor: "#2BB344",
                                              border: "1px solid #2BB344",
                                              transition: "all 0.3s linear",
                                              "&:hover": {
                                                color: "#2BB344",
                                                background: "#fff",
                                                border: "1px solid #2BB344",
                                                transition: "all 0.3s linear",
                                              },
                                            }}
                                            size={"small"}
                                            component={"a"}
                                            href={
                                              "https://techworth.trueselfy.com/"
                                            }
                                          >
                                            Check TechWorth
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Grid>
                                {/* <Grid
                                  container
                                  sx={{
                                    // p: { xs: "10px, 10px", lg: "10px 45px", xl: "10px 45px" },
                                    py: { xs: "20px", md: "10px", lg: "10px" },
                                    px: { xs: "20px", md: "45px", xl: "45px" },
                                    background: "rgba(43,179,68,15%)",
                                    borderRadius: "15px",
                                    mt: 4,
                                  }}
                                >
                                  <Grid item xs={12} lg={8} xl={7}>
                                    <Box>
                                      <Typography
                                        sx={{
                                          color: "#2BB344",
                                          fontFamily: "poppins",
                                          fontWeight: 700,
                                        }}
                                        variant="h2"

                                      >
                                        Are you underpaid?
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                      >
                                        Determine your potential salary by
                                        calculating TechWorth
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    xl={5}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        pb: { xs: 2, sm: 2, md: 2, lg: 0 },
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          // maxWidth: "370px",
                                          // width: isMobile ? "100%" : "100%",
                                          // display: "block",
                                          // m: "0px auto",
                                          color: "#FFF",
                                          px: "26px",
                                          fontSize: isMobile ? "16px" : "18px",
                                          fontWeight: 600,
                                          borderRadius: "55px",
                                          textAlign: "center",
                                          textTransform: "capitalize",
                                          background: "#2BB344",
                                          backgroundColor: "#2BB344",
                                          border: "1px solid #2BB344",

                                          "&:hover": {
                                            color: "#2BB344",
                                            background: "#fff",
                                            border: "1px solid #2BB344",
                                            transition: "all 0.3s linear",
                                          },
                                        }}
                                        size={"large"}
                                        component={"a"}
                                        href={
                                          "https://techworth.trueselfy.com/"
                                        }
                                      >
                                        Check TechWorth
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid> */}
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Box textAlign={"left"} pt={0.5}>
                              <Alert
                                severity="info"
                                sx={{ background: "#fff" }}
                              >
                                Recommendations and potential salary increase
                                percentages provided are for informational
                                purposes only and should be used as suggestions,
                                not guarantees
                              </Alert>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* AI Boost powered Section */}
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={5}
                          sx={{ alignItems: "flex-end" }}
                        >
                          {checkSkill !== "" &&
                          poweredByChatGPT &&
                          !chatgptError ? (
                            <Grid item xs={12} xl={12} id={"chat-gpt-result"}>
                              <Joyride
                                steps={InsightsSteps}
                                run={insightsStep}
                                continuous
                                showSkipButton
                                disableCloseOnEsc
                                disableOverlayClose
                                disableScrolling
                              />

                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: "52px",
                                    mt: { xs: "45px", sm: "0px" },
                                  }}
                                >
                                  <Box sx={{ px: 1 }}>
                                    <Typography
                                      color={"primary"}
                                      sx={{
                                        fontFamily: "poppins",
                                        fontSize: { xs: "30px", md: "45px" },
                                        fontWeight: 600,
                                        textAlign: "center",
                                        wordBreak: "break-word",
                                        color: "#020D17",
                                      }}
                                    >
                                      Career Insights by ChatGPT
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <img
                                      src="/assets/images/chat-gpt-logo.svg"
                                      alt="powered-by-chat-gpt"
                                      loading="lazy"
                                    />
                                  </Box>
                                </Box>
                                {/* Career Insights by ChatGPT section */}
                                {chatGptButton ? (
                                  <InsightsSkeleton />
                                ) : (
                                  <>
                                    <GetInsights
                                      checkSkill={checkSkill}
                                      subSkills={subSkills}
                                      chatGptButton={chatGptButton}
                                      chatGPTData={chatGPTData}
                                      interviewQuestionsLoading={
                                        interviewQuestionsLoading
                                      }
                                      interviewQuestions={interviewQuestions}
                                      disruptCareerData={disruptCareerData}
                                      Hide_google_trends={Hide_google_trends}
                                      RetryGPT={RetryGPT}
                                      addSkillRelevance={addSkillRelevance}
                                      easeOfLearning={easeOfLearning}
                                      PotentialSalaryIncraese={
                                        PotentialSalaryIncraese
                                      }
                                      isMobile={isMobile}
                                      initialCount={initialCount}
                                    />
                                  </>
                                )}
                              </>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </motion.div>

                  <Toaster
                    position="top-center"
                    limit={1}
                    toastOptions={{
                      style: {
                        wordBreak: "break-all",
                        maxWidth: 800,
                      },
                    }}
                  >
                    {(t) => (
                      <ToastBar
                        toast={t}
                        style={{
                          // overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {({ icon, message }) => (
                          <>
                            {icon}
                            {message}
                            {t.type !== "loading"}
                          </>
                        )}
                      </ToastBar>
                    )}
                  </Toaster>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
        <Box
          sx={{
            position: {
              lg: "unset",
              sm: "unset",
              xs: "unset",
              md: "unset",
              xl: "unset",
            },
            bottom: "0px",
            width: "100%",
            mt: { xs: isLoggedIn ? 22 : 2, sm: 2, lg: isLoggedIn ? 22 : 2 },
          }}
        >
          <FooterComponent />
        </Box>
        {/* <Box
          sx={{
            position: "fixed",
            // transform: "translateZ(0px)",
            flexGrow: 1,
            alignItems: "center",
            display: "flex",
            zIndex: 9999,
            bottom: 15,
            right: "5%",
            gap: { xs: 0, md: 2, lg: 3 },
            justifyContent: "center",
          }}
        >
          <SpeedDial ariaLabel="SpeedDial basic example" icon={<ShareIcon />}>
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
              />
            ))}
          </SpeedDial>
        </Box> */}
      </React.Fragment>
    </ThemeProvider>
  );
}

export default TechRelevanceV4;
