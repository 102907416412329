import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  CircularProgress,
  Skeleton,
  Box,
} from "@mui/material";
const getRandomWidth = () => Math.floor(Math.random() * 100);

const InsightsSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 0, md: 2 }}
      gap={{ xs: 2, md: 0 }}
      sx={{
        p: { xs: "20px 10px", md: "46px 34px 46px 20px" },
        backgroundColor: "#f9f9f9",
        borderRadius: { xs: "20px", md: "66px" },
        // ml: { xs: 1 },
      }}
    >
      {/* Header Section */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            <Skeleton variant="circular" width={50} height={50} />
          </Typography>
          <Typography
            variant="h4"
            align="center"
            style={{ fontWeight: "bold", marginLeft: "20px" }}
          >
            <Skeleton width={200} height={"80px"} />
          </Typography>
        </Box>
      </Grid>

      {/* Subskills Section */}
      <Grid item xs={12} md={3}>
        <Card
          sx={{
            borderRadius: "39px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <Skeleton
                width="60%"
                style={{
                  margin: "5px auto",
                }}
                height={50}
              />
            </Typography>
            <Grid
              container
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
              style={{ marginTop: "10px" }}
            >
              {[...Array(18)].map((_, index) => (
                <Grid item key={index}>
                  <Skeleton
                    variant="rectangular"
                    width={110}
                    // width={0}
                    height={30}
                    style={{ borderRadius: "20px" }}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Enterprise Adoption and Popularity */}
      <Grid item xs={12} md={3}>
        <Card
          sx={{
            borderRadius: "39px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <CardContent
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <Skeleton
                width={"60%"}
                style={{
                  margin: "5px auto",
                }}
                height={50}
              />
            </Typography>

            <Skeleton
              variant="circular"
              width={100}
              height={100}
              style={{ margin: "20px auto" }}
            />

            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                // marginTop: "20px",
                margin: "5px auto",
                textAlign: "center",
              }}
            >
              <Skeleton
                width="60%"
                height={50}
                style={{ margin: "5px auto" }}
              />
            </Typography>

            <Skeleton
              variant="circular"
              width={100}
              height={100}
              style={{ margin: "20px auto" }}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Learn & Earn Recommendations */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: "39px",

            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <Skeleton
                width="60%"
                style={{
                  margin: "5px auto",
                }}
                height={50}
              />
            </Typography>
            <table style={{ width: "100%", marginTop: "10px" }}>
              <thead>
                <tr>
                  <th>
                    <Skeleton width="80%" height={30} />
                  </th>
                  <th>
                    <Skeleton width="80%" height={30} />
                  </th>
                  <th>
                    <Skeleton width="80%" height={30} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton width="80%" height={35} />
                    </td>
                    <td>
                      <Skeleton width="85%" height={35} />
                    </td>
                    <td>
                      <Skeleton width="65%" height={35} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
      </Grid>

      {/* Interview Questions */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: "39px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <Skeleton
                width="60%"
                height={50}
                style={{
                  margin: "5px auto",
                }}
              />
            </Typography>
            {[...Array(6)].map((_, index) => (
              <Typography
                variant="body2"
                style={{ marginTop: "10px" }}
                key={index}
              >
                <Skeleton width="100%" animation="wave" />
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Grid>

      {/* Game-Changers Section */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: "39px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <Skeleton
                width="60%"
                height={50}
                style={{
                  margin: "5px auto",
                }}
              />
            </Typography>
            {[...Array(6)].map((_, index) => (
              <Typography
                variant="body2"
                style={{ marginTop: "10px" }}
                key={index}
              >
                <Skeleton width="100%" animation="wave" />
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InsightsSkeleton;
