import React, { useState } from "react";
import "./ChatGPTStyle.css";

const InterviewQuestions = ({ response }) => {
  const [showAll, setShowAll] = useState(false); // Controls whether to show all questions

  // Split the response into individual questions
  const responseLines = response
    .split("\n")
    .filter((line) => line.trim() !== "")
    .map((line) => line.replace(/\*\*/g, "").replace(/###/g, ""));

  // Separate the first two questions and the rest
  const firstTwoQuestions = responseLines.slice(0, 2);
  const otherQuestions = responseLines.slice(2);

  return (
    <div className="chatgpt-container">
      {/* Display the first two questions */}
      {firstTwoQuestions.map((line, index) => (
        <div key={index} className="chatgpt-message">
          {line}
        </div>
      ))}

      {/* Show "Show More" button if there are additional questions */}
      {!showAll && otherQuestions.length > 0 && (
        <span
          className="show-more"
          style={{ cursor: "pointer" }}
          onClick={() => setShowAll(true)}
        >
          Read More
        </span>
      )}

      {/* Display the rest of the questions when "Show More" is clicked */}
      {showAll &&
        otherQuestions.map((line, index) => (
          <div key={index} className="chatgpt-message">
            {line}
          </div>
        ))}
    </div>
  );
};

export default InterviewQuestions;
