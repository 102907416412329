import React from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  Skeleton,
} from "@mui/material";
import SubSkills from "./Subskills";
import PopularityChart from "../PopularityChartV2";
import IndustrialUsageChart from "../IndustrialUsageChart";
import InterviewQuestions from "../Components/common/InterviewQuestions";
import DynamicContentRenderer from "../Components/common/DynamicContentRender";
import GoogleTrendsEmbed from "../Components/GoogleTrends";
import { RocketLaunch } from "@mui/icons-material";

function GetInsights({
  checkSkill,
  subSkills,
  chatGptButton,
  chatGPTData,
  interviewQuestionsLoading,
  interviewQuestions,
  disruptCareerData,
  Hide_google_trends,
  RetryGPT,
  addSkillRelevance,
  easeOfLearning,
  PotentialSalaryIncraese,
  isMobile,
  initialCount,
  randomIntFromInterval,
}) {
  return (
    <Box
      component="div"
      sx={{
        backgroundImage: `url('/assets/images/tech-relevence-chatgpt-bg-02.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        p: { xs: "0px", sm: "20px ", md: "46px 44px" },
        borderRadius: {
          xs: "20px",
          md: "66px",
        },
        overflow: "hidden",
        border: {
          sm: "2px solid rgba(102,82,228,0.25)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "43px",
        }}
      >
        <Box
          sx={{
            mr: {
              xs: "10px",
              sm: "10px",
              md: "20px",
            },
            width: { xs: "46px", md: "58px" },
            height: "100%",
          }}
        >
          <img
            src="/assets/images/bulb-img-01.svg"
            width="100%"
            height="100%"
            alt={"bulb-img"}
            loading="lazy"
          />
        </Box>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: {
              xs: "24px",
              md: "32px",
            },
            fontWeight: 700,
            color: "#262655",
          }}
        >
          {" "}
          {checkSkill}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          {subSkills.length !== 0 ? (
            <Box
              sx={{
                border: "1px solid #000088",
                borderRadius: "39px",
                background: "#F3FBFF",
                height: "100%",
                boxSizing: "inherit",
              }}
              className={"sub-skills"}
              p={{ xs: 1, md: 3, lg: 2 }}
            >
              <Typography
                color="primary"
                sx={{
                  fontFamily: "poppins",
                  textAlign: "center",
                  fontSize: {
                    xs: "24px",
                    lg: "28px",
                  },
                  fontWeight: 700,
                  // mb: `${isMobile ? "0px" : "40px"}`,
                  color: "#000088",
                }}
              >
                Sub skills
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  mb: 2,
                  flexDirection: {
                    xs: "column",
                    md: "column",
                  },
                  alignItems: {
                    xs: "center",
                    md: "center",
                  },
                }}
              >
                <Box>
                  {/* <Typography
                                        variant="body2"
                                        sx={{
                                          textAlign: {
                                            xs: "center",
                                            md: "center",
                                          },
                                          pt: { xs: 1, md: 1 },
                                          pb: 1,
                                        }}
                                      >
                                        (Ease of learning)
                                      </Typography> */}
                  <SubSkills
                    subSkills={subSkills}
                    parentName={checkSkill}
                    bgColor={"#fff"}
                    avatarIcon={""}
                    chatGptButton={chatGptButton}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                // maxWidth: "377px",
                // width: "100%",
                border: "1px solid #000088",
                // p: "30px",
                borderRadius: "39px",
                background: "#F3FBFF",
                height: "100%",
                boxSizing: "inherit",
              }}
              p={{ xs: 1, md: 3, lg: 2 }}
              className={"skill-popularity"}
            >
              <Typography
                color="primary"
                sx={{
                  fontFamily: "poppins",
                  textAlign: "center",
                  fontSize: {
                    xs: "24px",
                    lg: "28px",
                  },
                  fontWeight: 700,
                  // mb: `${isMobile ? "0px" : "48px"}`,
                  color: "#000088",
                }}
              >
                Popularity
              </Typography>
              {chatGptButton ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: 5,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box pt={{ xs: 0, md: 0, lg: 6 }}>
                  <PopularityChart
                    value={parseInt(chatGPTData?.popularityRating) * 10 || 10}
                    color={"#4A3D9E"}
                  />
                </Box>
              )}
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <Box
            sx={{
              border: "1px solid #000088",
              borderRadius: "39px",
              background: "#F3FBFF",
              height: "100%",
              boxSizing: "inherit",
            }}
            className={"enterprise-adoption"}
            p={{ xs: 1, md: 3, lg: 2 }}
          >
            <Typography
              color="primary"
              sx={{
                fontFamily: "poppins",
                textAlign: "center",
                fontSize: {
                  xs: "24px",
                  lg: "28px",
                },
                fontWeight: 700,
                // mb: `${isMobile ? "0px" : "40px"}`,
                color: "#000088",
              }}
            >
              Enterprise Adoption
            </Typography>
            {chatGptButton ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  my: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IndustrialUsageChart
                  value={parseInt(chatGPTData?.percentageUsage || 10)}
                  color={"#4A3D9E"}
                />
              </Box>
            )}

            <>
              {subSkills?.length !== 0 && (
                <>
                  <Typography
                    color="primary"
                    sx={{
                      fontFamily: "poppins",
                      textAlign: "center",
                      fontSize: {
                        xs: "24px",
                        lg: "28px",
                      },
                      fontWeight: 700,
                      // mb: `${isMobile ? "0px" : "48px"}`,
                      color: "#000088",
                    }}
                  >
                    Popularity
                  </Typography>
                  <>
                    {chatGptButton ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          my: 5,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Box
                        pt={{
                          xs: 0,
                          md: 0,
                          lg: 6,
                        }}
                      >
                        <PopularityChart
                          value={
                            parseInt(chatGPTData?.popularityRating) * 10 || 10
                          }
                          color={"#4A3D9E"}
                        />
                      </Box>
                    )}
                  </>
                </>
              )}
            </>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Box
            sx={{
              // maxWidth: "377px",
              // width: "100%",
              border: "1px solid #000088",
              // p: "30px 62px",
              borderRadius: "39px",
              background: "#F3FBFF",
              height: "100%",
              boxSizing: "inherit",
            }}
            className={"lear-earn-recommendations"}
            p={{ xs: 1, md: 3, lg: 2 }}
          >
            <Typography
              color="primary"
              sx={{
                fontFamily: "poppins",
                textAlign: "center",
                // fontSize: {
                //   xs: "21px",
                //   sm: "24px",
                //   md: "24px",
                //   lg: "32px",
                //   xl: "32px",
                // },
                fontSize: {
                  xs: "24px",
                  lg: "28px",
                },
                fontWeight: 700,
                color: "#000088",
                // mb: "10px",
              }}
            >
              Learn & Earn Recommendations
            </Typography>
            {chatGptButton ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  my: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
              // pl={{ xs: 0, md: 2, xl: 3 }}
              // mt={{ xs: 0, md: 3, lg: 3, xl: 3 }}
              >
                {chatGPTData?.latestTechnologies &&
                Array.isArray(
                  chatGPTData?.latestTechnologies || chatGPTData
                ) ? (
                  <TableContainer
                    sx={
                      {
                        // overflowX: "hidden",
                      }
                    }
                  >
                    <Table
                      sx={{
                        minWidth: 200,
                        overflowX: "hidden",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: `${isMobile ? "12px" : "22px"}`,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Skill
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: `${isMobile ? "12px" : "22px"}`,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Ease of Learning
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: `${isMobile ? "12px" : "22px"}`,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Potential Salary Increase
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chatGPTData?.latestTechnologies?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                display: "flex",
                                border: "none",
                              }}
                            >
                              <img
                                style={{
                                  maxWidth: `${isMobile ? "25px" : "30px"}`,
                                }}
                                loading="lazy"
                                src="../../assets/images/fire-icon.svg"
                                alt="fire"
                              />
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: `${isMobile ? "12px" : "22px"}`,
                                  fontWeight: 400,
                                  textAlign: "left",
                                  paddingLeft: "8px",
                                }}
                                component="a"
                                href="#"
                                onClick={() => addSkillRelevance(item)}
                              >
                                {item?.skillName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: `${isMobile ? "12px" : "22px"}`,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {item?.easeOfLearning ||
                                  (item?.easeOfLearning?.length === 0
                                    ? easeOfLearning[
                                        randomIntFromInterval(0, 2)
                                      ]
                                    : easeOfLearning[
                                        randomIntFromInterval(0, 2)
                                      ])}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: `${isMobile ? "12px" : "22px"}`,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {item?.potentialSalaryIncreasePercentage ||
                                  (item?.potentialSalaryIncreasePercentage
                                    ?.length === 0
                                    ? PotentialSalaryIncraese[
                                        randomIntFromInterval(0, 7)
                                      ]
                                    : PotentialSalaryIncraese[
                                        randomIntFromInterval(0, 7)
                                      ])}
                                %
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Box>
                      <img
                        src="../../assets/images/empty-list.svg"
                        alt="data not found"
                        loading="lazy"
                        style={{
                          maxWidth: `${isMobile ? "50%" : "200px"}`,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        textAlign: "center",
                        fontSize: {
                          xs: "18px",
                          lg: "21px",
                          xl: "24px",
                        },
                      }}
                    >
                      Recommendations are not found
                    </Typography>
                    {initialCount <= 3 && (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          flexWrap: "wrap",
                          px: 2,
                          py: { xs: 1, lg: 0 },
                        }}
                      >
                        <Typography pr={1}>Retry with </Typography>
                        <Button
                          sx={{
                            maxWidth: "200px",
                            width: "100%",
                            marginLeft: {
                              sm: "15px",
                            },
                            color: "#FFF",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            padding: "7px 12px",
                            borderRadius: "27px",
                            border: "1px solid rgba(74,61,158,1)",

                            // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                            background:
                              "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                            transition: "all 0.3s linear",
                            "&:hover": {
                              border: "1px solid rgba(74,61,158,1)",
                              // background: "rgba(0, 158, 247, 0.10)",
                              background: "#FFF",
                              color: "rgba(108,82,229,1)",
                              transition: "all 0.3s linear",
                            },
                            "&:active,&:focus": {
                              background: "#FFF",
                              padding: "4px 10px",
                              border: "2px solid #2F2876",
                              color: "#2F2876 !important",
                            },
                          }}
                          endIcon={
                            <RocketLaunch
                              sx={{
                                fontSize: "16px",
                              }}
                            />
                          }
                          onClick={() => RetryGPT(checkSkill)}
                        >
                          Get insights
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        {interviewQuestions?.length > 0 && (
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Box
              sx={{
                // maxWidth: "377px",
                // width: "100%",
                border: "1px solid #000088",
                // p: "30px 62px",
                borderRadius: "39px",
                background: "#F3FBFF",
                height: "100%",
                boxSizing: "inherit",
              }}
              className={"interview-questions"}
              p={{ xs: 0, md: 3, lg: 1 }}
            >
              <Typography
                color="primary"
                sx={{
                  fontFamily: "poppins",
                  textAlign: "center",
                  // fontSize: {
                  //   xs: "21px",
                  //   sm: "24px",
                  //   md: "24px",
                  //   lg: "32px",
                  //   xl: "32px",
                  // },
                  fontSize: {
                    xs: "24px",
                    lg: "28px",
                  },
                  fontWeight: 700,
                  color: "#000088",
                  // mb: "10px",
                  p: { xs: 1, md: 3, lg: 1 },
                }}
              >
                Top Interview Questions for &nbsp;
                <span
                  style={{
                    fontWeight: 700,
                    color: "#262655",
                  }}
                >
                  {checkSkill}
                </span>
              </Typography>
              {interviewQuestionsLoading ? (
                <Box sx={{ px: "16px", py: "15px" }}>
                  {[...Array(8)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant={"rectangular"}
                      height={"15px"}
                      style={{
                        margin: "10px auto",
                      }}
                    />
                  ))}
                </Box>
              ) : (
                <>
                  <InterviewQuestions response={interviewQuestions} />
                </>
              )}
            </Box>
          </Grid>
        )}
        {disruptCareerData?.length > 0 && (
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Box
              sx={{
                // maxWidth: "377px",
                // width: "100%",
                border: "1px solid #000088",
                // p: "30px 62px",
                borderRadius: "39px",
                background: "#F3FBFF",
                height: "100%",
                boxSizing: "inherit",
              }}
              className={"disrupt-career-box"}
              p={{ xs: 0, md: 3, lg: 1 }}
            >
              <Typography
                color="primary"
                sx={{
                  fontFamily: "poppins",
                  textAlign: "center",

                  fontSize: {
                    xs: "22px",
                    lg: "28px",
                  },
                  fontWeight: 700,
                  color: "#000088",
                  // mb: "10px",
                  p: { xs: 1, md: 0 },
                }}
              >
                Game-Changers That Could Redefine Your Career and Key Skills
              </Typography>
              {interviewQuestionsLoading ? (
                <Box sx={{ px: "16px", py: "15px" }}>
                  {[...Array(8)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant={"rectangular"}
                      height={"15px"}
                      style={{
                        margin: "10px auto",
                      }}
                    />
                  ))}
                </Box>
              ) : (
                <DynamicContentRenderer content={disruptCareerData} />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      {Hide_google_trends === false && (
        <Box
          sx={{
            // maxWidth: "377px",
            // width: "100%",
            border: "1px solid #000088",
            // p: "30px 62px",
            borderRadius: "39px",
            background: "#F3FBFF",
            height: "100%",
            boxSizing: "inherit",
            p: 2,
            mt: 3,
          }}
        >
          <GoogleTrendsEmbed keyword={checkSkill} geo="" time="today 5-y" />
        </Box>
      )}
    </Box>
  );
}

export default GetInsights;
