import React, { useState } from "react";
import { Typography, Box, Grid, Divider, Button } from "@mui/material";

const DynamicContentRenderer = ({ content }) => {
  const [showAll, setShowAll] = useState(false); // State to control visibility of all questions

  const parseContent = (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");

    return lines.map((line, index) => {
      const cleanedLine = line.replace(/\*\*/g, ""); // Remove ** from the text

      if (line.startsWith("###")) {
        return (
          <Typography key={index} variant="h6" gutterBottom>
            {cleanedLine.replace("###", "").trim()}
          </Typography>
        );
      } else if (line.startsWith("-")) {
        return (
          <Typography
            key={index}
            variant="body1"
            style={{ paddingLeft: "1rem", fontWeight: "bold" }}
          >
            {cleanedLine.replace("-", "").trim()}
          </Typography>
        );
      } else if (/^\d+\./.test(line)) {
        return (
          <Typography key={index} variant="body1" gutterBottom>
            {cleanedLine}
          </Typography>
        );
      } else {
        return (
          <Typography key={index} variant="body2" paragraph>
            {cleanedLine}
          </Typography>
        );
      }
    });
  };

  const parsedContent = parseContent(content);

  // Separate first two lines and remaining content
  const firstTwoLines = parsedContent.slice(0, 1);
  const remainingLines = parsedContent.slice(2);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            {/* Render the first two lines */}
            {firstTwoLines.map((line, index) => (
              <Box key={index}>
                {line}
                <Divider sx={{ marginY: 1 }} />
              </Box>
            ))}

            {/* Show "Show More" button if there are more lines */}
            {!showAll && remainingLines.length > 0 && (
              <span className="show-more" onClick={() => setShowAll(true)}>
                Read More
              </span>
            )}

            {/* Render remaining lines if "Show More" is clicked */}
            {showAll &&
              remainingLines.map((line, index) => (
                <Box key={index}>
                  {line}
                  {index !== remainingLines.length - 1 && (
                    <Divider sx={{ marginY: 1 }} />
                  )}
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DynamicContentRenderer;
