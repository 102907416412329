import React, { useState, useEffect } from "react";
import { Box, Fade } from "@mui/material";
import Lottie from "react-lottie";

const EmojiIcon = ({ lottieFiles, interval = 500 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  // Function to get a random index
  const getRandomIndex = () => Math.floor(Math.random() * lottieFiles.length);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setFadeIn(false);

  //       // Wait for fade-out to complete before updating animation
  //       setTimeout(() => {
  //         setCurrentIndex(getRandomIndex()); // Set random index instead of sequential
  //         setFadeIn(true);
  //       }, interval / 2); // Half the interval for fade-out
  //     }, interval);

  //     return () => clearInterval(intervalId);
  //   }, [lottieFiles, interval]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "38px", // Adjust as per your needs
        width: "38px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Fade in={fadeIn} timeout={interval / 2}>
        <Box
          sx={{
            position: "absolute",
            transform: "translateY(-20px)",
            animation: fadeIn ? "fade-up 0.5s ease-out forwards" : undefined,
            "@keyframes fade-up": {
              "0%": {
                opacity: 0,
                transform: "translateY(40px)",
              },
              "100%": {
                opacity: 1,
                transform: "translateY(0)",
              },
            },
          }}
        >
          <Lottie
            options={{
              animationData: lottieFiles[currentIndex],
              loop: true,
              autoplay: true,
            }}
            height={38} // Match the Box height
            width={38} // Match the Box width
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default EmojiIcon;
