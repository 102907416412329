import axios from "axios";
import { toast } from "react-hot-toast";

let axiosInstance = axios.create({
  timeout: 200000,
});

export const setupAuthInterceptor = (getAuthState) => {
  axiosInstance.interceptors.request.use(async (req) => {
    if (req.url.indexOf("token") === -1 && req.url.indexOf("s3") === -1) {
      const authState = await getAuthState();
      // console.log("authState", authState);
      req.headers.access_token = authState.access_token;
      req.headers.Authorization = authState.id_token;
    }
    // Important: request interceptors **must** return the request.
    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      // console.log("response", err);
      if (err?.response?.status === 401) {
        window.location.replace("/");
      }
      throw err;
    }
  );
};

const OK_STATUSES = [200, 204];

const CONTENT_TYPES = {
  json: "application/json",
  form: "application/x-www-form-urlencoded",
};

export const apiTimeout = { timeout: 80000 };

export const defaultApiError =
  "Looks like there's a problem on our end, please try again";

export const isResponseOK = (response) =>
  response && OK_STATUSES.includes(response.status);

export const getUserError = ({
  response,
  apiMessages,
  defaultError = defaultApiError,
}) =>
  response && response.data
    ? apiMessages[response.data.message] || defaultError
    : defaultError;

const getAdditionalHeaders = (headers = {}) => ({
  ...headers,
});

export const getContentType = (type) => type || "json";

export const POST = async ({
  endpoint,
  payload = {},
  dispatch,
  options = {},
  navigate,
  setUserResult,
}) => {
  try {
    let uri = endpoint;

    if (!options.fullUrl) {
      uri = process.env.REACT_APP_API_BASE_URL + endpoint;
    }

    return await axiosInstance.post(uri, payload, {
      headers: {
        "Content-Type": CONTENT_TYPES[getContentType(options.type)],
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
    });
  } catch (err) {
    // if (err.response.status === 401) {
    // window.location.replace("/");
    // toast.error("Your Session was expire Please Login again");
    // }
    // dispatch(setLoading(false));
    // console.log("An error occurred", err);
    return err;
  }
};
export const GET = async ({ endpoint, options = {}, cancelToken }) => {
  try {
    return await axiosInstance.get(
      process.env.REACT_APP_API_BASE_URL + endpoint,
      {
        headers: {
          ...getAdditionalHeaders(options.headers),
        },
        ...apiTimeout,
        cancelToken: cancelToken,
      }
    );
  } catch (err) {
    return err;
  }
};

export default {
  POST,
  GET,
};
