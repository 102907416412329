import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Tab,
  Tabs,
  styled,
} from "@mui/material";

// Function to determine background and border color based on easeOfLearning
const getChipColors = (easeOfLearning) => {
  if (easeOfLearning >= 70) {
    return { backgroundColor: "#A8E6A3", borderColor: "#388E3C" }; // Light Green background, Dark Green border
  } else if (easeOfLearning >= 50 && easeOfLearning < 70) {
    return { backgroundColor: "#FFD580", borderColor: "#FFB300" }; // Light Orange background, Dark Orange border
  } else {
    return { backgroundColor: "#FFA6A6", borderColor: "#D32F2F" }; // Light Red background, Dark Red border
  }
};

// Styled Tab
const CustomTab = styled(Tab)(({ color }) => ({
  textTransform: "none",
  fontWeight: 600,
  fontSize: "14px",
  margin: "0 8px",
  padding: "8px 8px",
  borderRadius: "8px",
  backgroundColor: color.backgroundColor,
  color: "#000",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: color.borderColor,
    border: `4.5px solid rgba(0, 0, 0, 0.25)`,
  },
}));

const SubSkills = ({ subSkills, chatGptButton }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Categorize subSkills
  const categories = {
    hard: subSkills.filter((sub) => sub.easeOfLearning < 50),
    medium: subSkills.filter(
      (sub) => sub.easeOfLearning >= 50 && sub.easeOfLearning < 70
    ),
    easy: subSkills.filter((sub) => sub.easeOfLearning >= 70),
  };

  // Map selectedTab index to categories
  const tabs = [
    {
      label: "Hard",
      skills: categories.hard,
      color: { backgroundColor: "#FFA6A6", borderColor: "#D32F2F" },
    },
    {
      label: "Medium",
      skills: categories.medium,
      color: { backgroundColor: "#FFD580", borderColor: "#FFB300" },
    },
    {
      label: "Easy",
      skills: categories.easy,
      color: { backgroundColor: "#A8E6A3", borderColor: "#388E3C" },
    },
  ];

  // Filter out empty categories for tab rendering
  const visibleTabs = tabs.filter((tab) => tab.skills.length > 0);

  // Update filtered subSkills based on visible tabs
  const filteredSubSkills = visibleTabs[selectedTab]?.skills || [];

  return (
    <Box>
      {visibleTabs.length > 0 ? (
        <>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {visibleTabs.map((tab, index) => (
                <CustomTab key={index} label={tab.label} color={tab.color} />
              ))}
            </Tabs>
          </Box>
          {chatGptButton ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ borderRadius: "15px", padding: 0.5 }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  margin: "0 8px",
                  justifyContent: "center",
                }}
              >
                {filteredSubSkills
                  ?.slice(0, 10)
                  .sort((a, b) => b.easeOfLearning - a.easeOfLearning)
                  .map((sub, index) => {
                    const { backgroundColor, borderColor } = getChipColors(
                      sub.easeOfLearning
                    );
                    return (
                      <Paper
                        key={index}
                        sx={{
                          p: 1,
                          borderRadius: "10px",
                          backgroundColor, // Dynamic background color
                          border: `1.5px solid ${borderColor}`, // Dynamic border color
                          minWidth: "120px",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                          {sub.subSkill}
                        </Typography>
                      </Paper>
                    );
                  })}
                {filteredSubSkills.length === 0 && (
                  <Box>No subskills found</Box>
                )}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box>No categories available</Box>
      )}
    </Box>
  );
};

export default SubSkills;
