import apis from "../../utils/apis"; // Replace with your API library import

export function fetchInterviewQuestions(
  item,
  relevanceResult,
  DataEncryption,
  DataDecryption,
  setInterviewQuestions,
  setDisruptCareerData,
  setInterviewQuestionsLoading
) {
  const MAX_RETRIES = 3; // Limit the number of retries
  let retryCount = 0;

  const makeApiCall = () => {
    apis
      .POST({
        endpoint: "/get-interviewQuestion",
        payload: DataEncryption({
          skillName: item?.name,
          resultId: relevanceResult.relevanceResultId,
        }),
        options: { type: "json" },
      })
      .then((object) => {
        try {
          const data = DataDecryption(object.data);
          setInterviewQuestions(data?.InterviewQuestion || []);
          setDisruptCareerData(data?.DisruptCareer || null);
        } catch (decryptionError) {
          console.error("Data decryption failed:", decryptionError);
          setInterviewQuestions("");
          setDisruptCareerData(null);
        } finally {
          setInterviewQuestionsLoading(false);
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);

        if (error?.response?.data?.status === 400 && retryCount < MAX_RETRIES) {
          retryCount += 1;
          console.log(`Retrying API call... Attempt ${retryCount}`);
          makeApiCall();
        } else {
          console.error(
            "Non-retryable error or retry limit reached. Stopping further attempts."
          );
          setInterviewQuestions("");
          setInterviewQuestionsLoading(false);
        }
        setInterviewQuestions("");
        setInterviewQuestionsLoading(false);
      });
  };

  // Trigger the API call initially
  setInterviewQuestionsLoading(true); // Ensure loading is set before starting the call
  makeApiCall();
}
