import React, { useState, useContext } from "react";
import {
  Modal,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  IconButton,
  FormLabel,
  Alert,
  Link,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { OAuthCtx } from "../AuthContext";
import { setupAuthInterceptor } from "../../utils/apis";
import { setUserName } from "../../utils/storage";
import "../../utils/amplifyConfig";
// import apis from "../../utils/apis";
import { motion } from "framer-motion";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme, useMediaQuery } from "@mui/material";

const SignInModal = ({ setOpen, open, userData }) => {
  //   const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  const { setAuthState } = useContext(OAuthCtx);
  const { getAuthState } = useContext(OAuthCtx);
  const [showEmailField, setShowEmailField] = useState(false);
  const [cognitoUserstate, setCognitoUserstate] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [headerText, setHeaderText] = useState("Sign In With");
  const methods = useForm({ criteriaMode: "all" });
  const [userEmail, setUserEmail] = useState("");
  const showLinkedInButton = useFeatureFlag("showLinkedInButton");
  const showGoogleButton = useFeatureFlag("showGoogleLoginButton");

  const OTP_ERROR_MSG = `Oh no, that doesn't look like a valid OTP! Please double-check and enter the OTP sent to ${getValues(
    "email"
  )}.`;

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setShowEmailField(false);
    setDisableBtn(false);
    setShowEmail(false);
    setShowOtp(false);
    setHeaderText("Sign In With");
    reset("");
  }, [open]);

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }

  function intToHex(nr) {
    const hex = nr.toString(16).padStart(2, "0");
    // Randomly convert hex characters to uppercase to meet the password policy
    return hex
      .split("")
      .map((c) => (Math.random() < 0.5 ? c.toUpperCase() : c))
      .join("");
  }
  const handleEmailButtonClick = () => {
    if (!isChecked) {
      setErrorMessage("Please agree to the Terms of Service & Privacy Policy");
      return;
    }
    setShowEmail(true);
    setHeaderText("Sign In With Email");
  };

  const onSubmitForm = async (data) => {
    // setShowEmail(true);
    // reset();
    // setDisableBtn(true);
    setLoadingButton(true);
    let email = data.email.toLowerCase();
    setUserEmail(data.email);
    setUserName(data.name);
    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        email: email,
      },
    };
    var respons = [];
    try {
      //Check and user add user
      respons = await Auth.signUp(params);
      setShowEmailField(true);
      setShowOtp(true);
      setHeaderText("Enter The OTP");
      setLoadingButton(false);
      // console.log("called");
    } catch (error) {
      if (error.message === "User already exists") {
        try {
          // console.log("sign in block");
          const cognitoUser = await Auth.signIn(email);
          setLoadingButton(false);
          setCognitoUserstate(cognitoUser);
          setShowOtp(true);
          setHeaderText("Enter The OTP");

          // setShowOTPForm(true);
          // const cstmError = { error: 0, errorMsg: "" };
          //   setLoadingButton(false);
          // setErrorHandler(cstmError);
          localStorage.setItem("userEmail", cognitoUser.username);
        } catch (error) {
          // const cstmError = { error: 1, errorMsg: error.message };
          // setErrorHandler(cstmError);
          //   setLoadingButton(false);
        }
      } else {
        // const cstmError = { error: 1, errorMsg: error.message };
        // setErrorHandler(cstmError);
        // setLoadingButton(false);
      }
    }
    if (respons.userConfirmed == true) {
      try {
        const cognitoUser = await Auth.signIn(email);
        setCognitoUserstate(cognitoUser);
        setShowOtp(true);

        // const cstmError = { error: 0, errorMsg: "" };
        // setErrorHandler(cstmError);
        localStorage.setItem("userEmail", cognitoUser.username);
        // setLoadingButton(false);
      } catch (error) {
        // const cstmError = { error: 1, errorMsg: error.message };
        // setErrorHandler(cstmError);
        // setLoadingButton(false);
      }
    }
  };
  // const handleGoogleButtonClick = () => {
  //   // Add Google login functionality
  // };
  // const handleLinkedInButtonClick = () => {
  //   // Add LinkedIn login functionality
  // };
  const handleOTPSubmit = async (data) => {
    // event.preventDefault();
    //const data = new FormData(event.currentTarget);
    // setLoadingButton(true);
    // console.log("otp form called");
    setLoadingButton(true);
    let otp = data.otp;
    let cognitoUser = {};
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUserstate, otp);
      if (cognitoUser.challengeName == "CUSTOM_CHALLENGE") {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
      }
      try {
        var currentSession = null;
        currentSession = await Auth.currentSession();
        await setAuthState(currentSession);
        setLoadingButton(false);
        setupAuthInterceptor(getAuthState);
        setOpen(false);
      } catch (e) {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
        setLoadingButton(false);
      }
    } catch (e) {
      setError("otp", {
        type: "NotAuthorizedException",
        message:
          "The OTP you have entered is invalid, and you have reached the maximum number of attempts allowed to enter it. Please return to the Sign In page and try again.",
      });
      setLoadingButton(false);
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    setAgreementCheck(!agreementCheck);
    // Clear error message when user checks the checkbox
    if (event.target.checked) {
      setErrorMessage("");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <Box sx={style}>
          <Box>
            <IconButton
              onClick={() => setOpen(false)}
              size="small"
              sx={{
                position: "absolute",
                top: "20px",
                right: "23px",
                zIndex: 99,
                background: "#fff",
                border: "1px solid #000",
                "&:hover": {
                  background: "#FFF",
                },
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ p: 3, borderRadius: 2 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 500,
                pb: 2,
                fontSize: { xs: "28px", md: "inherit" },
              }}
            >
              {headerText}
            </Typography>
            <Grid container gap={2} justifyContent="center">
              {!showEmail ? (
                <>
                  <Grid item xs={10}>
                    <FormControlLabel
                      // sx={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "center",
                      // }}0.5
                      sx={{ ml: { xs: 2.5, sm: 4, md: 4 } }}
                      control={
                        <Checkbox
                          size="small"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I agree to the
                          <Link
                            href="https://vucaware.com/terms-and-conditions/"
                            target="_blank"
                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                          >
                            Terms of Service
                          </Link>
                          &
                          <Link
                            href="https://vucaware.com/privacy-policy/"
                            target="_blank"
                            sx={{ marginLeft: 0.5 }}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>
                  {showGoogleButton && (
                    <Grid item xs={10}>
                      <Button
                        variant="text"
                        fullWidth
                        sx={{
                          display: "flex",
                          gap: 1,
                          px: 3,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "55px",
                          color: "rgb(35, 35, 35)",
                          fontSize: { xs: "15px", md: "24px" },
                          fontWeight: 500,
                          // p: { xs: "6px 16px", md: "8px 22px", lg: "8px 24px" },
                          border: "2px solid #878787",
                          textTransform: "unset",
                          "&:hover,&:focus": {
                            border: "2px solid #26801E",
                          },
                        }}
                        disabled={disableBtn}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            // if (MaintenanceMessage != "undefined") {
                            //   setResponsiveDialog(true);
                            //   return;
                            // }
                            if (!isChecked) {
                              setErrorMessage(
                                "Please agree to the Terms of Service & Privacy Policy"
                              );
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "Google",
                            });
                            // console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img
                          src="../../../assets/images/google-logo.png"
                          style={{
                            maxWidth: "36px",
                          }}
                          loading="lazy"
                          height="auto"
                          alt="google-logo"
                        />{" "}
                        Google
                      </Button>
                    </Grid>
                  )}

                  {showLinkedInButton && (
                    <Grid item xs={10}>
                      <Button
                        variant="text"
                        fullWidth
                        sx={{
                          display: "flex",
                          gap: 1,
                          px: 3,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "55px",
                          color: "rgb(35, 35, 35)",
                          fontSize: { xs: "15px", md: "24px" },
                          fontWeight: 500,
                          // p: { xs: "6px 16px", md: "8px 22px", lg: "8px 24px" },
                          border: "2px solid #878787",
                          textTransform: "unset",

                          "&:hover,&:focus": {
                            border: "2px solid #007BB5",
                            background: "rgba(0,123,181,0.04)",
                          },
                        }}
                        disabled={disableBtn}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            // if (MaintenanceMessage != "undefined") {
                            //   setResponsiveDialog(true);
                            //   return;
                            // }
                            if (!isChecked) {
                              setErrorMessage(
                                "Please agree to the Terms of Service & Privacy Policy"
                              );
                              return;
                            }
                            sessionStorage.setItem(
                              "clickedSocialMediaLogin",
                              true
                            );
                            await Auth.federatedSignIn({
                              provider: "LinkedIn",
                            });
                            // console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during LinkedIn federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img
                          src="../../../assets/images/linkedin-square.png"
                          style={{
                            maxWidth: "36px",
                          }}
                          loading="lazy"
                          height="auto"
                          alt="google-logo"
                        />{" "}
                        Linkedin
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={10}>
                    <Box>
                      <Button
                        variant="text"
                        sx={{
                          border: "2px solid #878787",
                          color: "rgb(35, 35, 35)",
                          borderRadius: "55px",
                          textTransform: "unset",

                          fontSize: { xs: "15px", md: "24px" },
                          "&:hover,&:focus": {
                            border: "2px solid rgb(30, 26, 20)",
                          },
                        }}
                        onClick={handleEmailButtonClick}
                        fullWidth
                      >
                        <EmailIcon fontSize="large" />
                        &nbsp;Email
                      </Button>
                    </Box>
                  </Grid>
                </>
              ) : showOtp ? (
                <>
                  <motion.div
                  // initial={{ opacity: 0.6, y: 100 }}
                  // whileInView={{ opacity: 0.8, y: 0 }}
                  // exit={{ opacity: 1, y: 0 }}
                  // transition={{ duration: 0.5 }}
                  >
                    <Grid
                      container
                      justifyContent={"center"}
                      component="form"
                      id="otp-form"
                      onSubmit={handleSubmit(handleOTPSubmit)}
                    >
                      <Grid item xs={12} sm={12}>
                        <Alert
                          icon={false}
                          severity="success"
                          sx={{
                            justifyContent: "center",
                            textAlign: "center",
                            mb: "20px",
                          }}
                        >
                          {/* Received in the email sent to your email id */}
                          {`We’ve sent a verification code to your email`}
                          <Typography
                            sx={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis", // Adds ellipsis when content overflows
                              maxWidth: "300px", // Limit max-width for better control over ellipsis
                              wordBreak: "break-word",
                            }}
                          >
                            {userEmail}
                          </Typography>
                        </Alert>
                        <Controller
                          name="otp"
                          control={control}
                          rules={{
                            required: "OTP is required",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: OTP_ERROR_MSG,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size={"small"}
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: {
                                    xs: "22px",
                                    lg: "55px !important",
                                  },
                                },
                              }}
                              error={errors?.otp ? true : false}
                              fullWidth
                              id="otp"
                              type="number"
                              label={`Enter the OTP`}
                              helperText={
                                <>{errors?.otp && errors?.otp.message}</>
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={10} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            py: 2,
                          }}
                        >
                          <LoadingButton
                            type="submit"
                            form={"otp-form"}
                            variant="contained"
                            sx={{
                              borderRadius: "55px",
                            }}
                            loading={loadingButton}
                          >
                            Submit
                          </LoadingButton>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {loadingButton ? (
                          <Box sx={{ textAlign: "center" }}>
                            <Link sx={{ color: "#d3d3d3" }}>
                              Back to Sign in
                            </Link>
                          </Box>
                        ) : (
                          <Box
                            sx={{ textAlign: "center" }}
                            onClick={() => {
                              setShowEmail(false);
                              setShowOtp(false);
                              setHeaderText("Sign In With");
                              reset("");
                            }}
                          >
                            <Link
                              disabled={loadingButton}
                              sx={{ cursor: "pointer" }}
                            >
                              Back to Sign in
                            </Link>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                  // initial={{ opacity: 0.6, y: 100 }}
                  // whileInView={{ opacity: 0.8, y: 0 }}
                  // exit={{ opacity: 1, y: 0 }}
                  // transition={{ duration: 0.5 }}
                  >
                    <FormProvider {...methods}>
                      <form id="sign-in" onSubmit={handleSubmit(onSubmitForm)}>
                        <Grid container spacing={2} justifyContent="center">
                          <Grid
                            item
                            xs={10}
                            sm={12}
                            md={12}
                            sx={{ paddingLeft: "0px !important" }}
                          >
                            <FormLabel
                              sx={{
                                fontFamily: "poppins",
                                fontSize: { xs: "20px", sm: "22px" },
                                fontWeight: 700,
                                lineHeight: "42px",
                                color: "#262655",
                                paddingLeft: "14px",
                              }}
                            >
                              Name
                            </FormLabel>
                            <Controller
                              control={control}
                              className={"name"}
                              name={"name"}
                              // sx={{
                              //   "& .Mui-focused": {
                              //     color: "#0391E8 !important",
                              //   },
                              // }}
                              render={({ field }) => (
                                <TextField
                                  id="name"
                                  {...field}
                                  fullWidth
                                  required
                                  label={"Enter your Name"}
                                  variant="outlined"
                                  // inputRef={textFieldRef}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      borderRadius: {
                                        xs: "22px",
                                        lg: "55px !important",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sm={12}
                            md={12}
                            sx={{ paddingLeft: "0px !important" }}
                          >
                            <FormLabel
                              sx={{
                                fontFamily: "poppins",
                                fontSize: { xs: "20px", sm: "22px" },

                                fontWeight: 700,
                                lineHeight: "42px",
                                color: "#262655",
                                paddingLeft: "14px",
                              }}
                            >
                              Email
                            </FormLabel>
                            <Controller
                              control={control}
                              name={"email"}
                              rules={{
                                required: "Email Address is required",
                                pattern: {
                                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                  message:
                                    "Oh no, that doesn't look like a valid email! Please double-check and try again.",
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  error={errors?.email ? true : false}
                                  id="email"
                                  label={"Enter your Email"}
                                  fullWidth
                                  required
                                  type="email"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      borderRadius: {
                                        xs: "22px",
                                        lg: "55px !important",
                                      },
                                    },
                                  }}
                                  helperText={
                                    <>
                                      {errors?.email && errors?.email.message}
                                      {errors?.email &&
                                      errors.type === "validation"
                                        ? errors?.email?.message
                                        : ""}
                                    </>
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{ paddingLeft: "0px !important" }}
                          >
                            <Box>
                              <LoadingButton
                                variant="contained"
                                size={"medium "}
                                form="sign-in"
                                fullWidth
                                type="submit"
                                sx={{
                                  borderRadius: "55px",
                                  minWidth: "110px",
                                }}
                                loading={loadingButton}
                              >
                                {/* <EmailIcon /> */}
                                GET OTP
                              </LoadingButton>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sx={{ pl: "0px !important" }}>
                            {loadingButton ? (
                              <Box sx={{ textAlign: "center" }}>
                                <Link sx={{ color: "#d3d3d3" }}>
                                  Back to Sign in
                                </Link>
                              </Box>
                            ) : (
                              <Box
                                sx={{ textAlign: "center" }}
                                onClick={() => {
                                  setShowEmail(false);
                                  setShowOtp(false);
                                  setHeaderText("Sign In With");
                                  reset("");
                                }}
                              >
                                <Link
                                  disabled={loadingButton}
                                  sx={{ cursor: "pointer" }}
                                >
                                  Back to Sign in
                                </Link>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </form>
                    </FormProvider>
                  </motion.div>
                </>
              )}
            </Grid>
          </Grid>
          {errorMessage && (
            <Typography
              variant="body2"
              color="error"
              sx={{ textAlign: "center" }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SignInModal;
