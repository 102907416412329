import React from "react";
import { useTheme } from "@mui/material/styles";

import ReactApexcharts from "react-apexcharts";

const series = [60];

function IndustrialUsageChart({ value, color }) {
  const theme = useTheme();

  const options = {
    // series: [70],
    colors: [color],

    plotOptions: {
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: "70%",
          background: "transparent",
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#EBEBEB",
          strokeWidth: "100%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "35px",
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#008",
            offsetY: 10,
            formatter: function (val) {
              return val + "%";
            },
          },
          total: {
            show: false,
          },
        },
      },
    },
  };

  return (
    <>
      <ReactApexcharts
        type="radialBar"
        // width={250}
        height={250}
        series={[value]}
        options={options}
      />
    </>
  );
}

export default IndustrialUsageChart;
