import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";

const JobSuggestionDataCard = ({ data }) => {
  const skills = Array.isArray(data?.skills)
    ? data.skills.join(", ")
    : data.skills;

  const getDomainFromUrl = (url) => {
    const domain = new URL(url).hostname;
    return domain;
  };

  const ensureHttpsUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return "https://" + url;
    }
    return url;
  };

  const indeedUrl = `${getDomainFromUrl(data?.indeedUrl)}/jobs?q=${skills}-${
    data?.currency
  }-${data?.upper_range}&l=${data.city}`;
  const fullindeedUrl = ensureHttpsUrl(indeedUrl);

  return (
    <Grid
      container
      sx={{
        py: { xs: "20px", md: "20px", lg: "20px" },
        px: { xs: "20px", md: "45px", xl: "45px" },
        background:
          "linear-gradient(135deg, rgba(43,179,68,0.2) 0%, rgba(43,179,68,0.1) 100%)",
        borderRadius: "15px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.02)",
        },
        mt: { xs: 3, lg: 0 },
      }}
    >
      <Grid item xs={12}>
        <Box>
          <Typography
            sx={{
              textAlign: { xs: "center", lg: "left" },
              color: "#2BB344",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: { xs: "22px", sm: "26px", lg: "26px", xl: "28px" },
              mb: 2,
            }}
          >
            {data.role} in{" "}
            <Typography
              component="span"
              sx={{
                color: "#43a856",
                fontSize: { xs: "22px", sm: "26px", lg: "26px", xl: "28px" },
                fontWeight: 500,
              }}
            >
              {data.city}
            </Typography>
          </Typography>
        </Box>

        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableBody
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "32px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: "#2BB344",

                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  Experience
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  {data.experience}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: "#2BB344",

                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  Skills
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  {skills}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: "#2BB344",
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  Number of Open Jobs
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  {data.number_of_open_jobs}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: "#2BB344",
                    backgroundColor: "#f9f9f9",
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  Salary Range
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    padding: { xs: "8px", md: "16px" },
                  }}
                >
                  {data?.currency} {data?.lower_range?.toLocaleString()} -{" "}
                  {data?.upper_range?.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 1,
            mt: 2,
          }}
        >
          <Link
            sx={{
              maxWidth: "125px",
              width: "100%",
              display: "inline-flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 500,
              color: "#2BB344",
              border: "2px solid #2BB344",
              p: "8px 12px",
              borderRadius: "24px",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "#0D9B21 !important",
                color: "#fff",
                borderColor: "#fff",
              },
            }}
            target="_blank"
            href={`https://www.linkedin.com/jobs/search/?currentJobId=3788215852&f_TPR=r604800&keywords=${skills}&location=${data.city}&origin=JOB_SEARCH_PAGE_JOB_FILTER`}
          >
            <img
              src="../../../assets/images/linkedin-square.png"
              alt="Linkedin"
              width="20px"
              loading="lazy"
            />
            Linkedin
            <KeyboardArrowRight />
          </Link>
          <Link
            sx={{
              maxWidth: "125px",
              width: "100%",
              display: "inline-flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 500,
              color: "#2BB344",
              border: "2px solid #2BB344",
              p: "8px 12px",
              borderRadius: "24px",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "#0D9B21 !important",
                color: "#fff",
                borderColor: "#fff",
              },
            }}
            target="_blank"
            href={fullindeedUrl}
            rel="noopener noreferrer"
          >
            <img
              src="../../../assets/images/indeed.png"
              alt="Indeed"
              width="20px"
              loading="lazy"
            />
            Indeed
            <KeyboardArrowRight />
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default JobSuggestionDataCard;
