// import { Auth } from "aws-amplify";
// import {
//   useForm,
//   FormProvider,
//   Controller,
//   useFormContext,
// } from "react-hook-form";
import React from "react";
import { Grid, Box, Chip } from "@mui/material";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
// import axios from "axios";
// import { motion } from "framer-motion";

const callBackUrl = (callBack, isParams, referralUrl) => {
  const isLocalhost = window.location.hostname === "localhost";

  // if (callBack === "signIn") {
  //   if (isLocalhost) {
  //     if (isParams === referralUrl) {
  //       return "http://localhost:3000/endorse-expertise";
  //     } else {
  //       return `http://localhost:3000${isParams}`;
  //     }
  //   } else {
  //     if (isParams === referralUrl) {
  //       return "https://master.d3hqz1cwjn0bmv.amplifyapp.com/endorse-expertise";
  //     } else {
  //       return `https://master.d3hqz1cwjn0bmv.amplifyapp.com${isParams}`;
  //     }
  //   }
  // } else {
  if (isLocalhost) {
    return "http://localhost:3000";
  } else {
    return `https://master.dn23lrv16hcl0.amplifyapp.com`;
  }
  // }
};

export const getRedirectSignInURL = () => {
  sessionStorage.setItem("userType", "auth-user");
  const NODE_ENV = process.env.REACT_APP_ENV;
  // const urlSearchString = window.location.search;
  // const params = new URLSearchParams(urlSearchString);
  // const redirectLink = params.get("link");
  // if (redirectLink === "/endorse-expertise") {
  //   saveTargetPath(redirectLink);
  // }
  // const isParams = getTargetPath();
  // const referralUrl = "/endorse-expertise";
  switch (NODE_ENV) {
    case "dev":
      return callBackUrl();
    case "test":
      return "https://master.dn23lrv16hcl0.amplifyapp.com";
    case "prod":
      return "https://www.techrelevance.trueselfy.com";
    default:
      return "https://www.techrelevance.trueselfy.com";
  }
};

export const getRedirectSignOutURL = () => {
  const NODE_ENV = process.env.REACT_APP_ENV;
  switch (NODE_ENV) {
    case "dev":
      return callBackUrl();
    case "test":
      return "https://master.dn23lrv16hcl0.amplifyapp.com";
    case "prod":
      return "https://www.techrelevance.trueselfy.com";
    default:
      return "https://www.techrelevance.trueselfy.com";
  }
};

export function GetBackGroundColor(skillDemand, type) {
  if (skillDemand === "Trending") {
    switch (type) {
      // case "y":
      //   return "#278C37";
      // case "tech":
      //   return "#278C37";
      // case "mgmt":
      //   return "#30B044";
      // case "management":
      //   return "#30B044";
      // case "generic":
      //   return "#38C94E";
      default:
        return "#278C37";
    }
  }
  if (skillDemand === "Obsolete") {
    switch (type) {
      // case "y":
      //   return "#028EDF";
      // case "tech":
      //   return "#028EDF";
      // case "mgmt":
      //   return "#09A2FA";
      // case "management":
      //   return "#09A2FA";
      // case "generic":
      //   return "#79CDFD";
      default:
        return "#028EDF";
    }
  }
  if (skillDemand === "In demand") {
    switch (type) {
      // case "y":
      //   return "#E9B112";
      // case "tech":
      //   return "#E9B112";
      // case "mgmt":
      //   return "#FEC113";
      // case "management":
      //   return "#FEC113";
      // case "generic":
      //   return "#FAD15D";
      default:
        return "#E9B112";
    }
  }
  if (skillDemand === "Getting outdated fast") {
    switch (type) {
      // case "y":
      //   return "#C60015";
      // case "tech":
      //   return "#C60015";
      // case "mgmt":
      //   return "#E2031B";
      // case "management":
      //   return "#E2031B";
      // case "generic":
      //   return "#FF465A";
      default:
        return "#C60015";
    }
  }
}

export function checkYearWithGpt(option) {
  const year = option?.expiryYear;
  const currentYear = new Date().getFullYear();
  const minYearDiff = 5;
  const minYearForOutdatedSkill = 4;
  const minYearForCurrentSkill = 1;

  if (!year) {
    return {
      label: "Default",
      barPrimaryColor: "#e0e0e0",
      barSecondaryColor: "#e0e0e0",
    };
  }

  if (year > currentYear) {
    if (option?.chat_gpt === false && option?.estYear === 2010) {
      return {
        label: `Obsolete`,
        barPrimaryColor: "red",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
        // color: "red",
        color: "#4fb7f7",
      };
    } else {
      return {
        label: `Trending`,
        barPrimaryColor: "green",
        // barSecondaryColor: "#A5D6A7",
        barSecondaryColor: GetBackGroundColor("Trending", option?.type),

        // color: "#26801E",
        color: "#58f374",
      };
    }
  } else if (year >= currentYear - minYearForCurrentSkill) {
    return {
      label: `In demand`,
      barPrimaryColor: "darkorange",
      // barSecondaryColor: "#ffcc80",
      barSecondaryColor: GetBackGroundColor("In demand", option?.type),
      // color: "darkorange",
      color: "#ffd560",
    };
  } else if (year >= currentYear - minYearForOutdatedSkill) {
    return {
      label: `Getting outdated fast`,
      barPrimaryColor: "#ffc107",
      // barSecondaryColor: "#fff59d",
      barSecondaryColor: GetBackGroundColor(
        `Getting outdated fast`,
        option?.type
      ),

      // color: "#FF9D09",
      color: "#f74861",
    };
  } else if (year <= currentYear - minYearDiff) {
    return {
      label: `Obsolete `,
      barPrimaryColor: "red",
      // barSecondaryColor: "#FFCDD2",
      barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
      // color: "red",
      color: "#4fb7f7",
    };
  }
}

function checkYear(year) {
  const currentYear = new Date().getFullYear();
  const minYearDiff = 5;
  const minYearForOutdatedSkill = 4;
  const minYearForCurrentSkill = 1;

  if (year > currentYear) {
    return {
      label: `Trending `,
      barPrimaryColor: "green",
      // barSecondaryColor: "#A5D6A7",
      barSecondaryColor: "#278C37",

      // color: "#26801E",
      color: "#58f374",
    };
  } else if (year >= currentYear - minYearForCurrentSkill) {
    return {
      label: ` In demand`,
      barPrimaryColor: "darkorange",
      // barSecondaryColor: "#ffcc80",
      barSecondaryColor: "#E9B112",
      // color: "darkorange",
      color: "#ffd560",
    };
  } else if (year >= currentYear - minYearForOutdatedSkill) {
    return {
      label: `Getting outdated fast`,
      barPrimaryColor: "#ffc107",
      // barSecondaryColor: "#fff59d",
      barSecondaryColor: "#C60015",

      // color: "#FF9D09",
      color: "#f74861",
    };
  } else if (year <= currentYear - minYearDiff) {
    return {
      label: `Obsolete `,
      barPrimaryColor: "red",
      // barSecondaryColor: "#FFCDD2",
      barSecondaryColor: "#028EDF",

      // color: "red",
      color: "#4fb7f7",
    };
  }
}

export const Legend = () => {
  const currentYear = new Date().getFullYear();
  const years = [
    currentYear + 1,
    currentYear,
    currentYear - 2,
    currentYear - 6,
  ];

  return (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      {/* <Grid item>
        <Chip
          label={`Searching...`}
          // className={year > currentYear ? "shine-effect" : ""}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            color: "rgba(0, 0, 0, 0.87)",
            border: "1px solid rgba(0, 0, 0, 0.36)",
            fontWeight: 600,
          }}
        />
      </Grid> */}
      {years.map((year, index) => {
        const yearInfo = checkYear(year);
        return (
          <Grid item key={index}>
            <Chip
              label={yearInfo.label}
              // className={year > currentYear ? "shine-effect" : ""}
              sx={{
                backgroundColor: yearInfo?.barSecondaryColor,
                color: "#fff" || "rgba(0, 0, 0, 0.87)",
                border: `1px solid ${yearInfo.color}`,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
